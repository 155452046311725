import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { EditStudentApplication } from "./EditStudentApplication";
import { SearchStudentApplicationsList } from "./SearchStudentApplicationsList";
import { PendingStudentApplicationsList } from "./PendingStudentApplicationsList";
import { CompletedStudentApplicationsList } from "./CompletedStudentApplicationsList";
import { RejectedStudentApplicationsList } from "./RejectedStudentApplicationsList";
import { FailedPaymentStudentApplicationsList } from "./FailedPaymentStudentApplicationsList";


export const studentApplicationRoutes: Array<RouteEntry> = [
    { path: '/administration/applications/search', exact: true, component: SearchStudentApplicationsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/applications/pending', exact: true, component: PendingStudentApplicationsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/applications/completed', exact: true, component: CompletedStudentApplicationsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/applications/rejected', exact: true, component: RejectedStudentApplicationsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/applications/failed', exact: true, component: FailedPaymentStudentApplicationsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/studentApplication-prices/edit/:id', component: EditStudentApplication, authorize: true, requireRole: IdentityRoles.Administration },
];
