import * as React from "react";
import { AsyncLoadResult, AsyncLoadOptions } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";
import { useAuthenticatedState } from "../../api/api-authorization/useAuthenticatedState";
import { isNullOrUndefined } from "util";
import { useCache } from "../../shared/useCache";
import { AccountService } from "./AccountService";

/**
 * Refresh the current user state using the accountService.
 * @param isAuthenticated
 */
const refreshCurrentUserRoles = async (isAuthenticated: boolean | null, accountService: AccountService, setRoles: (value: RolesState) => void): Promise<void> => {
    // Do nothing if we don't know our authentication state.
    if (isNullOrUndefined(isAuthenticated)) {
        return;
    }

    // If we are not authenticated then we have no roles.
    if (!isAuthenticated) {
        setRoles({ isAuthenticated: false, roles: [] });
        return;
    }

    // Otherwise we are authenticated, so load our roles.
    let result = await accountService.currentUserRoles();
    setRoles({ isAuthenticated: true, roles: result.roles });
};

interface RolesState {
    isAuthenticated: boolean,
    roles: Array<string>,
}

/**
 * Returns the list of roles for the current user.
 */
export function useCurrentUserRoles(options: AsyncLoadOptions = {}): AsyncLoadResult<{ roles: Array<string> | null }> {
    const { isAuthenticated } = useAuthenticatedState();
    const [roles, setRoles] = useCache<RolesState>('currentUserRoles');
    const accountService = useAccountService();

    const [refresh, { isExecuting: isLoading, errors }] = useAsyncCallback(async () => {
        await refreshCurrentUserRoles(isAuthenticated, accountService, setRoles);      
    }, [accountService, isAuthenticated]);

    React.useEffect(() => {
        if (options.lazy) {
            return;
        }

        // Refresh from the store if we need to.
        if (isNullOrUndefined(isAuthenticated)) {
            return;
        }

        if (!roles || roles.isAuthenticated !== isAuthenticated) {
            refresh();
        }
    }, [refresh, options.lazy, isAuthenticated, roles]);

    // TODO handle options.pollInterval.

    return {
        data: { roles: roles?.roles ?? null },
        refresh: refresh,
        isLoading: isLoading,
        errors: errors
    };
}


/**
 * Clear the cached user roles.
 */
export function useResetCurrentUserRolesCallback() {
    const { isAuthenticated } = useAuthenticatedState();
    const [/*roles*/, setRoles] = useCache('currentUserRoles');
    const accountService = useAccountService();

    const [refresh] = useAsyncCallback(async () => {
        await refreshCurrentUserRoles(isAuthenticated, accountService, setRoles);
    }, [accountService, isAuthenticated]);

    return React.useCallback(() => {
        refresh();
    }, [refresh]);
}
