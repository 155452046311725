import './evidence.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Input, Button, Spinner, Media, FormGroup } from 'reactstrap';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsyncCallback } from "react-use-async-callback";
import { useUploadBlobCallback } from '../../../api/main/blobReferences/useUploadBlobCallback';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { Guid } from 'guid-string';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface evidenceUnder19Props {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    evidenceBlob: BlobReference | undefined,
    setEvidenceBlob: (value: BlobReference | undefined) => void,
    save: () => Promise<void>,
}

/**
 * Wizard step to upload applicant's evidence photo if under 19.
 */
export const EvidenceUnder19 = (props: evidenceUnder19Props) => {
    const {
        model, change,
        pushStep, popStep,
        evidenceBlob, setEvidenceBlob,
        save,
    } = props;

    const { t } = useTranslation();

    // Upload a photo of the applicant's proof of identity.
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();
    const [uploadFile, { isExecuting: isUploadingFile, errors: uploadFileErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the file.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        change({ proofOfEntitlementBlobReferenceId: blob.id });
        setEvidenceBlob(blob);
    }, [uploadBlob, change, t, setEvidenceBlob]);

    const evidenceImage = `${stylesPaths.publicRoot}/img/id.jpg`;
    const evidenceImageAlt = t('evidenceImage.alt', 'Upload image');

    const imageToUse = React.useMemo(() => {
        if (!model) {
            return evidenceImage;
        }

        return evidenceBlob ? evidenceBlob.url : evidenceImage;

    }, [model, evidenceBlob]);

    // Can we continue to the next page?
    const canContinue = React.useMemo(() => {
        if (!model) {
            return false;
        }

        return !Guid.isEmpty(model.proofOfEntitlementBlobReferenceId);
    }, [model]);

    // Save the application to the database and progress to the next page.
    const [saveAndNext, { isExecuting: isSaving }] = useAsyncCallback(async () => {
        // Save everthing we've done so far to the database.
        await save();

        // Progress to the next page.

        // if there is a university id then no need to collect
        if (!model.universityId) {
            pushStep(ApplyWizardStep.pay)
        } else {
            pushStep(ApplyWizardStep.success)
        }

    }, [save, pushStep, model]);

    return (
        <>
            <AlertOnErrors errors={[uploadBlobErrors, uploadFileErrors]} />
            <Media className="mb-4 ml-auto mr-auto evidence-image" src={imageToUse} alt={evidenceImageAlt} />
            <FormGroup className="text-center">
                <Label className="btn btn-outline-primary evidence-upload-button">
                    {
                        isUploadingFile ? (
                            <>
                                <Spinner size="sm" />
                                <> </>{t('evidence.uploading', 'Uploading...')}
                            </>
                        ) : (
                                <>{t('evidence.uploadFile', 'Upload file...')}</>
                            )
                    }

                    <Input type="file" name="files" onChange={e => uploadFile(e.currentTarget.files)} />
                </Label>
            </FormGroup>
            <div className="alert">
                If you’re Under 19 you must provide proof of age. Upload a photo of your photo ID that shows your date of birth e.g. passport or driving licence.  <br/><br/>
                Travel can be purchased for use until the end of the academic year when the course ends.
            </div>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                        <ButtonAsync color="primary" disabled={!canContinue} onClick={() => saveAndNext()}
                            isExecuting={isSaving}
                            executingChildren={<><Spinner size="sm" /><> </>{t('evidence.saving', 'Saving application...')}</>}
                            >
                            {t('common.next', 'Next')}
                            <> </>
                            <FontAwesomeIcon icon="chevron-right" />
                        </ButtonAsync>
                    </Col>
                </Row>
            </div>
        </>
    );
};