import * as React from 'react';
import { studentApplicationsQuery_items } from '../../api/main/generated/studentApplicationsQuery';
import { StudentApplicationsList } from './StudentApplicationsList';
import { useTranslation } from 'react-i18next';
import { calculateApplicationStatus } from '../../utilities/calculateApplicationStatus';

/**
 * List of payment failure applications.
 */
export const FailedPaymentStudentApplicationsList = () => {
    const { t } = useTranslation();

    // Filter to show only payment failures - if archived and paymentTakenDate is not set it is a rejection not a payment failure
    const filter = React.useCallback((items: Array<studentApplicationsQuery_items> | undefined | null) => {
        return items?.filter(item => calculateApplicationStatus(item) === 'Payment failed');
    }, []);

    return (
        <StudentApplicationsList filter={filter} includeArchived={true}
            title={t('failedPaymentStudentApplicationsList.title', 'Failed Payments')}
        />
    );
};