import * as React from 'react';
import { studentApplicationsQuery_items } from '../../api/main/generated/studentApplicationsQuery';
import { StudentApplicationsList } from './StudentApplicationsList';
import { useTranslation } from 'react-i18next';
import { calculateApplicationStatus } from '../../utilities/calculateApplicationStatus';

/**
 * List of completed applications.
 */
export const CompletedStudentApplicationsList = () => {
    const { t } = useTranslation();

    // Filter to show only completed items
    const filter = React.useCallback((items: Array<studentApplicationsQuery_items> | undefined | null) => {
        return items?.filter(item => calculateApplicationStatus(item) === 'Issued');
    }, []);

    return (
        <StudentApplicationsList filter={filter}
            title={t('completedStudentApplicationsList.title', 'Issued cards')} />
    );
};
