import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { UniversitiesList } from "./UniversitiesList";
import { EditUniversity, CreateUniversity } from "./EditUniversity";
import { DeleteUniversity } from "./DeleteUniversity";


export const universityRoutes: Array<RouteEntry> = [
    { path: '/administration/bulk-buyers', exact: true, component: UniversitiesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/bulk-buyers/edit/:id', component: EditUniversity, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/bulk-buyers/add', component: CreateUniversity, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/bulk-buyers/delete/:id', component: DeleteUniversity, authorize: true, requireRole: IdentityRoles.Administration },
];
