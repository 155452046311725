import * as React from 'react';
import { StudentApplicationsList } from './StudentApplicationsList';
import { useTranslation } from 'react-i18next';

/**
 * List of all applications that can be searched.
 */
export const SearchStudentApplicationsList = () => {
    const { t } = useTranslation();

    return (
        <StudentApplicationsList includeArchived={true}
            title={t('searchStudentApplicationsList.title', 'Search applications')}
        />
        );
};