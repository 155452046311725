import './editProduct.scss';

import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useProduct } from '../../api/main/products/useProduct';
import { useSaveProductCallback } from '../../api/main/products/useSaveProductCallback';
import { Product } from '../../api/main/models/Product';
import { Background } from '../shared/Background';
import { TwoValueSwitch } from '../shared/TwoValueSwitch';

interface EditProductProps {
    isCreate?: boolean,
}

/**
 * Create a new product.
 */
export const CreateProduct = () => (<EditProduct isCreate={true} />);

/**
 * Edit an product
 */
export const EditProduct = (props: EditProductProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useProduct(id);
    const { model, change, changes } = useChanges<Product>(storeModel, isCreate ? { id: Guid.newGuid(), name: '', archived: false, price: 0, isUnder19: false, isAnydayPass: false, isCityArea: true, anydayDays: 0 } : {});
    const [save, { errors: saveErrors }] = useSaveProductCallback();
    const history = useHistory();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editProduct.nameRequired', 'Name is required') : '',
            anydayDays: () =>
                model?.isAnydayPass &&
                    model?.anydayDays !== 150 &&
                    model?.anydayDays !== 100 &&
                    model?.anydayDays !== 50 &&
                    model?.anydayDays !== 20 ? t('editProduct.anydayDays50or100', 'Anyday days must be 20, 50, 100, or 150') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editProduct.createHeading', 'Add product pricing')}</>
                                ) : (
                                        <>{t('editProduct.editHeading', 'Edit product pricing')}</>
                                    )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors]} />

                <Form id="edit-product" onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editProduct.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="name">{t('editProduct.price', 'Price')}</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{t('editProduct.currencySign', '£')}</InputGroupText>
                            </InputGroupAddon>
                            <ValidatedInput name="price" type="number" value={model.price ?? ''} onChange={e => change({ price: e.currentTarget.value })} onBlur={e => validate('price')} validationErrors={validationErrors['price']} />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="settings">
                            {t('editProduct.settings', 'What does this price apply to?')}
                        </Label>

                        <Row>
                            {/*
                            <Col xs={12} md="">
                                <FormGroup>
                                    <span className="sr-only">
                                        <Label htmlFor="isUnder19">{t('editProduct.isUnder19.label', 'Student or under 19?')}</Label>
                                    </span>
                                    <TwoValueSwitch leftLabel={t('editProduct.isUnder19.student', 'Student')} rightLabel={t('editProduct.isUnder19.under19', 'Under 19')}
                                        checked={model?.isUnder19} onChange={checked => change({ isUnder19: checked })} />
                                </FormGroup>
                            </Col>
                            */}
                            <Col xs={12} md="">
                                <FormGroup>
                                    <span className="sr-only">
                                        <Label htmlFor="isAnydayPass">{t('editProduct.isAnydayPass.label', 'Everyday academic year or Anyday pass?')}</Label>
                                    </span>
                                    <TwoValueSwitch leftLabel={t('editProduct.isAnydayPass.everyday', 'Everyday')} rightLabel={t('editProduct.isAnydayPass.anyday', 'Anyday')}
                                        checked={model?.isAnydayPass} onChange={checked => {
                                            if (checked) {
                                                change({ isAnydayPass: checked });
                                            } else {
                                                change({
                                                    isAnydayPass: checked,
                                                    anydayDays: 0,
                                                });
                                            }
                                        }} />
                                </FormGroup>
                            </Col>
                            <ConditionalFragment showIf={model.isAnydayPass}>
                                <Col xs={12} md="">
                                    <FormGroup>
                                        <Label htmlFor="anydayDays">{t('editProduct.anydayDays.label', 'Number of days (20, 50, 100, 150)?')}</Label>
                                        <ValidatedInput name="anydayDays" type="text" value={model.anydayDays ?? '0'} onChange={e => change({ anydayDays: parseInt(e.currentTarget.value) })} onBlur={e => validate('anydayDays')} validationErrors={validationErrors['anydayDays']} />
                                    </FormGroup>
                                </Col>
                            </ConditionalFragment>
                            <Col xs={12} md="">
                                <FormGroup>
                                    <span className="sr-only">
                                        <Label htmlFor="isCityArea">{t('editProduct.isCityArea.label', 'City area or Further?')}</Label>
                                    </span>
                                    <TwoValueSwitch leftLabel={t('editProduct.isCityArea.cityArea', 'City area')} rightLabel={t('editProduct.isCityArea.further', 'Further')}
                                        checked={!model?.isCityArea} onChange={checked => change({ isCityArea: !checked })} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
