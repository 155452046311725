import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col, Button } from 'reactstrap';
import { ChoiceButton } from '../shared/ChoiceButton';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface IsUnder19Props {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    calculateProduct: () => void,
    isCurrentStepComplete: boolean,
    stepCompleted: ApplyWizardStep | undefined,
    setStepCompleted: (value: ApplyWizardStep) => void,
}

/**
 * Wizard step to decide who pays.
 */
export const IsUnder19 = (props: IsUnder19Props) => {
    const {
        model,
        change,
        pushStep, popStep,
        calculateProduct,
        isCurrentStepComplete,
        stepCompleted,
        setStepCompleted,
   } = props;

    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className={'text-right choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ isUnder19: false }); calculateProduct(); pushStep(ApplyWizardStep.isAnyday); setStepCompleted(!stepCompleted || ApplyWizardStep.isUnder19 > stepCompleted ? ApplyWizardStep.isUnder19 : stepCompleted); }}
                        title={t('isUnder19.student', 'Student Pass')}
                        text={t('isUnder19.studentt', 'I am a student who is 19 or over')}
                        imageURL={`${stylesPaths.publicRoot}/img/NTSU.jpg`}
                        className={!model.isUnder19 && isCurrentStepComplete ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
                <Col className={'text-left choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ isUnder19: true }); calculateProduct(); pushStep(ApplyWizardStep.isAnyday); setStepCompleted(!stepCompleted || ApplyWizardStep.isUnder19 > stepCompleted ? ApplyWizardStep.isUnder19 : stepCompleted); }}
                        title={t('isUnder19.under19', 'Under 19')}
                        text={t('isUnder19.under19t', 'I am a student who is under 19')}
                        imageURL={`${stylesPaths.publicRoot}/img/id.jpg`}
                        className={model.isUnder19 ? 'highlight-selected' : ''}
                   >
                    </ChoiceButton>
                </Col>
            </Row>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                    </Col>
                </Row>
            </div>
        </>
        );
};