import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col, Button } from 'reactstrap';
import { ChoiceButton } from '../shared/ChoiceButton';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface AnydayDaysProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    calculateProduct: () => void,
    isCurrentStepComplete: boolean,
    stepCompleted: ApplyWizardStep | undefined,
    setStepCompleted: (value: ApplyWizardStep) => void,
}

/**
 * Wizard step for Anyday to decide 20 days or 50 days or 100 days or 150 days.
 */
export const AnydayDays = (props: AnydayDaysProps) => {
    const {
        model,
        change,
        pushStep, popStep,
        calculateProduct,
        isCurrentStepComplete,
        stepCompleted,
        setStepCompleted,
   } = props;

    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col xs={6} lg={3} className={'text-right choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ anydayDays: 20 }); calculateProduct(); pushStep(ApplyWizardStep.isCityArea); setStepCompleted(!stepCompleted || ApplyWizardStep.anydayDays > stepCompleted ? ApplyWizardStep.anydayDays : stepCompleted); }}
                        title={t('anydayDays.20', '20 days')}
                        text={t('anydayDays.20t', 'I want to bulk buy 20 individual days of travel')}
                        imageURL={`${stylesPaths.publicRoot}/img/20_Overlay.jpg`}
                        className={!model.isUnder19 && isCurrentStepComplete ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
                <Col xs={6} lg={3} className={'text-right choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ anydayDays: 50 }); calculateProduct(); pushStep(ApplyWizardStep.isCityArea); setStepCompleted(!stepCompleted || ApplyWizardStep.anydayDays > stepCompleted ? ApplyWizardStep.anydayDays : stepCompleted); }}
                        title={t('anydayDays.50', '50 days')}
                        text={t('anydayDays.50t', 'I want to bulk buy 50 individual days of travel')}
                        imageURL={`${stylesPaths.publicRoot}/img/50_Overlay.jpg`}
                        className={!model.isUnder19 && isCurrentStepComplete ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
                <Col xs={6} lg={3} className={'text-left choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ anydayDays: 100 }); calculateProduct(); pushStep(ApplyWizardStep.isCityArea); setStepCompleted(!stepCompleted || ApplyWizardStep.anydayDays > stepCompleted ? ApplyWizardStep.anydayDays : stepCompleted); }}
                        title={t('anydayDays.100', '100 days')}
                        text={t('anydayDays.100t', 'I want to bulk buy 100 individual days of travel')}
                        imageURL={`${stylesPaths.publicRoot}/img/100_Overlay.jpg`}
                        className={model.isUnder19 ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
                <Col xs={6} lg={3} className={'text-left choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ anydayDays: 150 }); calculateProduct(); pushStep(ApplyWizardStep.isCityArea); setStepCompleted(!stepCompleted || ApplyWizardStep.anydayDays > stepCompleted ? ApplyWizardStep.anydayDays : stepCompleted); }}
                        title={t('anydayDays.150', '150 days')}
                        text={t('anydayDays.150t', 'I want to bulk buy 150 individual days of travel')}
                        imageURL={`${stylesPaths.publicRoot}/img/150_Overlay.jpg`}
                        className={model.isUnder19 ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
            </Row>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                    </Col>
                </Row>
            </div>
        </>
        );
};