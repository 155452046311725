import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { useAuthenticatedState } from '../../api/api-authorization/useAuthenticatedState';
import { useCurrentUserRoles } from '../../api/account';

export interface AuthorizeContainerProps {
    requireRole?: string | Array<string>,
    children?: React.ReactNode,
}

/**
 * Container component that will check for authorization before showing its children.
 * @param props
 */
export const AuthorizeContainer = (props: AuthorizeContainerProps) => {
    const { isAuthenticated } = useAuthenticatedState();
    const { data: { roles: usersRoles } } = useCurrentUserRoles();
    const [ hasRequiredRole, setHasRequiredRole ] = React.useState<boolean | null>(); 

    // Load the roles only if we need to.
    React.useEffect(() => {
        if (props.requireRole && !isNullOrUndefined(usersRoles)) {
            const requireRoles = [props.requireRole].flat(2);
            let hasRole = false;
            for (let roleToCheck of requireRoles) {
                if (usersRoles.find(it => it === roleToCheck)) {
                    hasRole = true;
                    break;
                }
            }
            setHasRequiredRole(hasRole);
        }
    }, [props.requireRole, usersRoles, setHasRequiredRole, isAuthenticated]);

    // Render the UI
    //
    const { children } = props;

    // Show nothing until we have a defined authenticated state.
    if (isNullOrUndefined(isAuthenticated)) {
        return (<></>);
    }

    // If we are not authenticted, then retun nothing.
    if (!isAuthenticated) {
        return (<></>);
    }

    // If we need to check a role but don't have the roles to check yet, show nothing.
    if (props.requireRole && isNullOrUndefined(usersRoles)) {
        return (<></>);
    }

    // If we checked a role and didn't have one we were looking for, show nothing.
    if (hasRequiredRole === false) {
        return (<></>);
    }

    // If we get here then we can render the children.
    return (<>{children}</>)
};
