import './sidebar.scss';

import * as React from 'react';
import { AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer } from '@coreui/react';
import { SidebarNav } from '../../shared/sidebarNav';
import { AppRoutes } from '../shared/AppRoutes';
import { sidebarRoutes } from '../../configure/routeConfig';
import { Navigation } from './Navigation';
import { Nav } from 'reactstrap';

export const Sidebar = () => {
    return (
        <AppSidebar fixed display="md">
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <Nav className="menu-nav">
                    <Navigation />
                </Nav>
                <Nav className="area-menu-nav">
                    <AppRoutes routes={sidebarRoutes} />
                </Nav>
            </SidebarNav>

            <AppSidebarFooter />
            <AppSidebarMinimizer />
        </AppSidebar>
    );
};
