import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplyWizardStep } from '../ApplyWizardStep';

export interface FailureProps {
    popStep: (untilStep?: ApplyWizardStep) => void,
}

/**
 * Wizard step to test payment.
 */
export const Failure = (props: FailureProps) => {
    const {
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <div className="mt-2 mb-2">
                <div className="alert alert-danger">
                    {t('failure.paymentfailed', 'Your payment was not successful. Please check the information you entered or contact your bank or card issuer.')}
                </div>
            </div>
        </>
        );
};