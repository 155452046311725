import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col, Button } from 'reactstrap';
import { ChoiceButton } from '../shared/ChoiceButton';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface IsAnydayProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    calculateProduct: () => void,
    isCurrentStepComplete: boolean,
    stepCompleted: ApplyWizardStep | undefined,
    setStepCompleted: (value: ApplyWizardStep) => void,
}

/**
 * Wizard step to decide Everyday or Anyday pass.
 */
export const IsAnyday = (props: IsAnydayProps) => {
    const {
        model,
        change,
        pushStep, popStep,
        calculateProduct,
        isCurrentStepComplete,
        stepCompleted,
        setStepCompleted,
   } = props;

    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className={'text-right choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ isAnydayPass: false }); calculateProduct(); pushStep(ApplyWizardStep.isCityArea); setStepCompleted(!stepCompleted || ApplyWizardStep.isAnyday > stepCompleted ? ApplyWizardStep.isAnyday : stepCompleted); }}
                        title={t('isAnydayPass.false', 'Everyday Academic Year')}
                        text={t('isAnydayPass.falset', 'Unlimited travel all day, every day (consecutive days) between 1st September 2023 and 31st July 2024.')}
                        imageURL={`${stylesPaths.publicRoot}/img/ER_Everyday_Cards.jpg`}
                        className={!model.isAnydayPass && isCurrentStepComplete ? 'highlight-selected' : ''}
                   >
                    </ChoiceButton>
                </Col>
                <Col className={'text-left choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ isAnydayPass: true }); pushStep(ApplyWizardStep.anydayDays); setStepCompleted(!stepCompleted || ApplyWizardStep.isAnyday > stepCompleted ? ApplyWizardStep.isAnyday : stepCompleted); }}
                        title={t('isAnydayPass.true', 'Easyrider Academic Anyday')}
                        text={t('isAnydayPass.truet', 'Unlimited travel all day, where you bulk buy individual days of travel and use each day as and when needed (non-consecutive days).')}
                        imageURL={`${stylesPaths.publicRoot}/img/ER_Anyday_Cards.jpg`}
                        className={model.isAnydayPass ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
            </Row>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                    </Col>
                </Row>
            </div>
        </>
        );
};