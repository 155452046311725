import './existingEasyrider.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Input, Button, Media } from 'reactstrap';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormContainer } from '../../shared/FormContainer';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface ExistingEasyriderProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    stepCompleted: ApplyWizardStep | undefined,
    setStepCompleted: (value: ApplyWizardStep) => void,
}

/**
 * Wizard step to capture an existing eastrider card number if there is one.
 */
export const ExistingEasyrider = (props:ExistingEasyriderProps) => {
    const {
        model, change,
        pushStep, popStep,
        stepCompleted,
        setStepCompleted,
    } = props;

    const { t } = useTranslation();
    const easyriderImage = `${stylesPaths.publicRoot}/img/ER_Everyday_Help.jpg`; 
    const easyriderImageAlt = t('easyriderImage.alt', 'Easyrider card');
    return (
        <>
            <Media className="mb-4 ml-auto mr-auto easyrider-image" src={easyriderImage} alt={easyriderImageAlt} />
            <FormContainer>
                <FormGroup>
                    <Label htmlFor="existingEasyrider">
                        {t('existingEasyrider.existingEasyRiderCardNumber.label', 'If you have an existing Easyrider card, we will add your travel to your existing card. We will not post you a new card. The travel will be available on your card within 3 working days and will activate when you present it to the on bus reader. Remember, Academic Year passes start from 1st September 2023. Please enter your existing Easyrider card below.  If you do not have an Easyrider card, please leave this box blank.')}
                    </Label>
                    <Input type="text" value={model?.existingEasyRiderCardNumber ?? ''} onChange={e => change({ existingEasyRiderCardNumber: e.currentTarget.value })} />
                </FormGroup>
            </FormContainer>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => {
                                // set the completed step 
                                setStepCompleted(!stepCompleted || ApplyWizardStep.existingEasyrider > stepCompleted ? ApplyWizardStep.existingEasyrider : stepCompleted);
                                // Progress to the next page.
                                pushStep(ApplyWizardStep.personalDetails);
                            }}>
                            {t('common.next', 'Next')}
                            <> </>
                            <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};