import gql from "graphql-tag";
import { universityFields } from "../generated/universityFields";

export const universityFieldsFragment = gql`
    fragment universityFields on University {
        id
        name
        archived
    }
`;


export type University = Omit<universityFields, '__typename'>;
