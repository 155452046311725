import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup, } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { TableRowButtons } from '../shared/TableRowButtons';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useStudentApplications } from '../../api/main/studentApplications/useStudentApplications';
import { Banner } from '../shared/Banner';
import { studentApplicationsQuery_items, } from '../../api/main/generated/studentApplicationsQuery';
import moment from 'moment';
import { calculateApplicationStatus } from '../../utilities/calculateApplicationStatus'

export interface StudentApplicationListProps {
    title?: string,
    // Optional filter that can be passed to the component to filter the items shown (e.g. by state).
    filter?: (items: Array<studentApplicationsQuery_items> | undefined | null) => Array<studentApplicationsQuery_items> | undefined | null,
    // When true we'll include the archived items when loading from the database (e.g. when showing rejected items).
    includeArchived?: boolean,
}

/**
 * List of studentApplications.
 */
export const StudentApplicationsList = (props: StudentApplicationListProps) => {
    const {
        title,
        filter, includeArchived,
    } = props;

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useStudentApplications({ includeArchived: includeArchived });

    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        // Filter by any passed in filter.
        let ret = allItems;
        if (filter) {
            ret = filter(ret) ?? [];
        }

        // Filter by the search.
        if (!search) {
            return ret;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return ret.filter(item =>
            item.forename.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.surname.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.university?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.product?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (calculateApplicationStatus(item)).toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search, filter]);

    // Make sure the URL keeps in sync with the search.
    useReplaceSearchParamsEffect({ search: search });

    const colCount = 8;

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{title ?? t('studentApplicationLists.heading', 'Student applications')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell d-lg-none">{t('studentApplicationsList.studentApplication', 'Application')}</th>
                            <th className="d-none d-lg-table-cell">{t('studentApplicationsList.submittedDate', 'Applied')}</th>
                            <th className="d-none d-lg-table-cell">{t('studentApplicationsList.forename', 'Forename')}</th>
                            <th className="d-none d-lg-table-cell">{t('studentApplicationsList.surname', 'Surname')}</th>
                            <th className="d-none d-lg-table-cell">{t('studentApplicationsList.email', 'Email')}</th>
                            <th className="d-none d-lg-table-cell">{t('studentApplicationsList.bulkBuyer', 'Payer')}</th>
                            <th className="d-none d-lg-table-cell">{t('studentApplicationsList.product.name', 'Card')}</th>
                            <th className="d-none d-lg-table-cell">{t('studentApplicationsList.status', 'Status')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => (
                                <tr key={item.id} onDoubleClick={e => history.push(`/administration/studentApplication-prices/edit/${item.id}`)}>
                                    <td className="d-table-cell d-lg-none">
                                        <div>{t('common.fullName', '{{forename}} {{surname}}', { forename: item.forename, surname: item.surname })}</div>
                                        <div className="text-muted">
                                            {item.university?.name ?? ''}
                                        </div>
                                    </td>
                                    <td className="d-none d-lg-table-cell">{moment(item.submittedDate).format("DD/MM/YYYY")}</td>
                                    <td className="d-none d-lg-table-cell">{item.forename}</td>
                                    <td className="d-none d-lg-table-cell">{item.surname}</td>
                                    <td className="d-none d-lg-table-cell">{item.email}</td>
                                    <td className="d-none d-lg-table-cell">{item.university?.name ?? 'Applicant'}</td>
                                    <td className="d-none d-lg-table-cell">{item.product?.name ?? ''}</td>
                                    <td className="d-none d-lg-table-cell">{calculateApplicationStatus(item)}</td>
                                    <td>
                                        <TableRowButtons>
                                            <ButtonGroup>
                                                <ConditionalFragment showIf={calculateApplicationStatus(item) === 'Pending'}>
                                                    <LinkContainer to={`/administration/studentApplication-prices/edit/${item.id}`}>
                                                        <Button color="primary" outline>
                                                            <FontAwesomeIcon icon="edit" />
                                                            <> {t('common.process', 'Process')}</>
                                                        </Button>
                                                    </LinkContainer>
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={calculateApplicationStatus(item) !== 'Pending'}>
                                                    <LinkContainer to={`/administration/studentApplication-prices/edit/${item.id}`}>
                                                        <Button color="primary" outline>
                                                            <FontAwesomeIcon icon="eye" />
                                                            <> {t('common.view', 'View')}</>
                                                        </Button>
                                                    </LinkContainer>
                                                </ConditionalFragment>
                                                {/*    no delete allowed - archiving is done by rejecting the application
                                                
                                                <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                    <DropdownToggle color="primary" outline caret>
                                                        <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <LinkContainer to={`/administration/studentApplication-prices/delete/${item.id}`}>
                                                            <DropdownItem className="text-danger">
                                                                <FontAwesomeIcon icon="trash" />
                                                                <> {t('common.delete', 'Delete')}</>
                                                            </DropdownItem>
                                                        </LinkContainer>
                                                    </DropdownMenu>
                                                    </ButtonDropdown>
                                                    */}
                                            </ButtonGroup>
                                        </TableRowButtons>
                                    </td>
                                </tr>
                            ))
                        }
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};

