import { RouteEntry } from "../../shared/routes";
import { AdministrationNavigation } from "./AdministrationNavigation";
import { Administration } from "./Administration";

export const administrationRoutes: Array<RouteEntry> = [
    { path: '/administration', exact: true, component: Administration, authorize: true },
];

export const administrationSidebarRoutes: Array<RouteEntry> = [
    { path: '/administration', component: AdministrationNavigation, authorize: true },
];
