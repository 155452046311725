import gql from "graphql-tag";
import { studentApplicationFields } from "../generated/studentApplicationFields";
import { universityFieldsFragment } from "./University";
import { productFieldsFragment } from "./Product";
import { blobReferenceFieldsFragment } from "./BlobReference";
import { userFieldsFragment } from "./User";

export const studentApplicationFieldsFragment = gql`
    fragment studentApplicationFields on StudentApplication {
        id
        forename
        surname
        street
        address2
        city
        county
        postcode
        country
        dateOfBirth
        email
        photoBlobReferenceId
        proofOfEntitlementBlobReferenceId
        isUnder19
        isAnydayPass
        isCityArea
        productId
        existingEasyRiderCardNumber
        universityId
        universityProvidedReferenceNumber
        hasBeenIssued
        submittedDate
        issuedOnDate
        issuedByUserId
        hasPaid
        price
        paymentProviderReferenceNumber
        paymentTakenDate
        archived
        anydayDays

        university {
            ...universityFields
        }

        product {
            ...productFields
        }

        photoBlobReference {
            ...blobReferenceFields
        }

        proofOfEntitlementBlobReference {
            ...blobReferenceFields
        }

        issuedByUser {
            ...userFields
        }
    }

    ${universityFieldsFragment}
    ${productFieldsFragment}
    ${blobReferenceFieldsFragment}
    ${userFieldsFragment}
`;


export type StudentApplication = Omit<studentApplicationFields, '__typename' | 'university' | 'product' | 'photoBlobReference' | 'user'>;
