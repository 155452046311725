import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { usePreparePaymentJson } from '../../../api/main/studentApplications/usePreparePaymentJson';
import { paymentConfig } from '../../../configure/paymentConfig';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product } from '../../../api/main/models/Product';

export interface PayProps {
    model: StudentApplication,
    //change: (changes: Partial<StudentApplication>) => void,
    //pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    //save: () => Promise<void>,
    product: Product | undefined | null,
}

/**
 * Wizard step to take payments from the user's card.
 */
export const PayReal = (props: PayProps) => {
    const {
        model,
        popStep,
        product,
    } = props;

    const { t } = useTranslation();

    const { data: { json: hppJson } } = usePreparePaymentJson(model.id);

    React.useEffect(() => {
        if (!hppJson) {
            return;
        }

        // NOTE these first two scripts are now incuded in index.html so don't get added here.
        //let script1 = document.createElement('script');
        //script1.src = 'https://code.jquery.com/jquery-3.5.0.js';
        //script1.async = false;
        //document.body.appendChild(script1);

        //let script2 = document.createElement('script');
        //script2.src = '/globalpay/rxp-js.js';
        //script2.async = false;
        //document.body.appendChild(script2);

        let script3 = document.createElement('script');
        script3.text = `
            RealexHpp.setHppUrl("${paymentConfig.serviceUrl}");
            RealexHpp.redirect.init("payButtonId", "${paymentConfig.redirectUrl()}", JSON.parse('${hppJson}'));
            `;
        //script3.text = `
        //    RealexHpp.setHppUrl("${paymentConfig.serviceUrl}");
        //    RealexHpp.embedded.init("payButtonId", "iFrameId", "${paymentConfig.redirectUrl()}", JSON.parse('${hppJson}'));
        //    //RealexHpp.lightbox.init("payButtonId", "${paymentConfig.redirectUrl()}", JSON.parse('${hppJson}'));
        //    `;
        script3.async = true;
        document.body.appendChild(script3);
    }, [hppJson]);

    return (
        <>
            <div>
                <p>
                    {t('payReal.paymentAmount', 'Your payment amount will be £{{paymentamount}}', { paymentamount: product?.price })}<br/> <br/>
                    {t('payReal.waitingMessage', 'You will now be redirected to our secure checkout.')}
                </p>
                <p>
                    <strong>{t('payReal.warningMessage', 'Warning do not navigate backwards through this process or risk paying twice.')}</strong>
                </p>
                <div className="mt-2 mb-2">
                    <Row>
                        <Col xs="auto">
                            <Button color="primary" onClick={() => popStep()}>
                                <FontAwesomeIcon icon="chevron-left" />
                                <> </>
                                {t('common.back', 'Back')}
                            </Button>
                        </Col>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" id="payButtonId" disabled={!hppJson}>
                                {t('payReal.payNow', 'Proceed to checkout')}
                                <> </>
                                <FontAwesomeIcon icon="chevron-right" />
                            </Button>
                        </Col>
                    </Row>
                </div>

            </div>
        </>
        );
};