import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { StudentApplicationCreateInput } from '../generated/globalTypes';
import { createStudentApplicationMutation, createStudentApplicationMutationVariables } from '../generated/createStudentApplicationMutation';
import { studentApplicationFieldsFragment } from '../models/StudentApplication';

/**
 * Get a callback to create a StudentApplication in the store.
 */
export function useCreateStudentApplicationCallback(): [(model: StudentApplicationCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createStudentApplicationMutation, createStudentApplicationMutationVariables>(
        gql`
            mutation createStudentApplicationMutation ($model: StudentApplicationCreateInput!) {
                createStudentApplication(model: $model) {
                    ...studentApplicationFields
                }
            }

            ${studentApplicationFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: StudentApplicationCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
