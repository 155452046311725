import './evidence.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Input, Button, Spinner, Media, FormGroup } from 'reactstrap';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsyncCallback } from "react-use-async-callback";
import { useUploadBlobCallback } from '../../../api/main/blobReferences/useUploadBlobCallback';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { Guid } from 'guid-string';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useSendPaymentConfirmEmailCallback } from '../../../api/main/studentApplications/useSendPaymentConfirmEmailCallback';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface evidenceProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    evidenceBlob: BlobReference | undefined,
    setEvidenceBlob: (value: BlobReference | undefined) => void,
    save: () => Promise<void>,
}

/**
 * Wizard step to upload applicant's evidence photo.
 */
export const Evidence = (props: evidenceProps) => {
    const {
        model, change,
        pushStep, popStep,
        evidenceBlob, setEvidenceBlob,
        save,
    } = props;

    const { t } = useTranslation();

    // Upload a photo of the applicant's proof of identity.
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();
    const [uploadFile, { isExecuting: isUploadingFile, errors: uploadFileErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the file.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        change({ proofOfEntitlementBlobReferenceId: blob.id });
        setEvidenceBlob(blob);
    }, [uploadBlob, change, t, setEvidenceBlob]);

    const evidenceImage = `${stylesPaths.publicRoot}/img/NTSU.jpg`;
    const evidenceImageAlt = t('evidenceImage.alt', 'Upload image');

    const imageToUse = React.useMemo(() => {
        if (!model) {
            return evidenceImage;
        }

        return evidenceBlob ? evidenceBlob.url : evidenceImage;

    }, [model, evidenceBlob]);

    // Can we continue to the next page?
    const canContinue = React.useMemo(() => {
        if (!model) {
            return false;
        }

        return !Guid.isEmpty(model.proofOfEntitlementBlobReferenceId);
    }, [model]);

    const [sendPaymentConfirmEmail, { /*isExecuting: isSendingPaymentConfirmEmail, */ errors: sendPaymentConfirmEmailErrors }] = useSendPaymentConfirmEmailCallback();

    // Save the application to the database and progress to the next page.
    const [saveAndNext, { isExecuting: isSaving }] = useAsyncCallback(async () => {
        // Save everthing we've done so far to the database.
        await save();

        // Progress to the next page.

        // if there is no university id then we need to collect otherwise straight to success
        if (!model.universityId) {
            pushStep(ApplyWizardStep.pay);
        } else {
            sendPaymentConfirmEmail(model.id);
            pushStep(ApplyWizardStep.success);
        }

    }, [save, pushStep, model]);

    return (
        <>
            <AlertOnErrors errors={[uploadBlobErrors, uploadFileErrors, sendPaymentConfirmEmailErrors]} />
            <Media className="mb-4 ml-auto mr-auto evidence-image" src={imageToUse} alt={evidenceImageAlt} />
            <FormGroup className="text-center">
                <Label className="btn btn-outline-primary evidence-upload-button">
                    {
                        isUploadingFile ? (
                            <>
                                <Spinner size="sm" />
                                <> </>{t('evidence.uploading', 'Uploading...')}
                            </>
                        ) : (
                                <>{t('evidence.uploadFile', 'Upload file...')}</>
                            )
                    }

                    <Input type="file" name="files" onChange={e => uploadFile(e.currentTarget.files)} />
                </Label>
            </FormGroup>
            <div className="alert">
                {t('evidence.para.1','To qualify for student discount, students must present (at the time of purchase) a valid photo ID issued by their UK college or university that shows a course expiry date. NUS Extra, ISIC and TOTUM cards are not accepted.')} <br/><br/>
                {t('evidence.para.1', 'Travel can only be purchased for periods of travel that do not exceed the expiry date shown on the Student ID.')}.
            </div>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                        <ButtonAsync color="primary" disabled={!canContinue} onClick={() => saveAndNext()}
                            isExecuting={isSaving}
                            executingChildren={<><Spinner size="sm" /><> </>{t('evidence.saving', 'Saving application...')}</>}
                            >
                            {t('common.next', 'Next')}
                            <> </>
                            <FontAwesomeIcon icon="chevron-right" />
                        </ButtonAsync>
                    </Col>
                </Row>
            </div>
        </>
    );
};