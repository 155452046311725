import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../shared/useFetchMoreCallback';
import { studentApplicationsQuery, studentApplicationsQueryVariables } from '../generated/studentApplicationsQuery';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { productFieldsFragment } from '../models/Product';
import { userFieldsFragment } from '../models/User';
import { universityFieldsFragment } from '../models/University';

interface StudentApplicationsAsyncLoadPagedOptions extends AsyncLoadPagedOptions {
    includeArchived?: boolean,
}

/**
 * Get a list of studentApplications from the store.
 * @param id
 * @param options
 */
export function useStudentApplications(options: StudentApplicationsAsyncLoadPagedOptions = {}): AsyncLoadPagedResult<studentApplicationsQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<studentApplicationsQuery, studentApplicationsQueryVariables>(
        gql`
        query studentApplicationsQuery ($offset: Int, $first: Int, $includeArchived: Boolean) {
            items: studentApplications (offset: $offset, first: $first, includeArchived: $includeArchived) {
                id
                forename
                surname
                street
                address2
                city
                county
                postcode
                country
                dateOfBirth
                email
                photoBlobReferenceId
                proofOfEntitlementBlobReferenceId
                isUnder19
                isAnydayPass
                isCityArea
                productId
                existingEasyRiderCardNumber
                universityId
                universityProvidedReferenceNumber
                hasBeenIssued
                submittedDate
                issuedOnDate
                issuedByUserId
                hasPaid
                price
                paymentProviderReferenceNumber
                paymentTakenDate
                archived
                anydayDays

                university {
                    ...universityFields
                }

                product {
                    ...productFields
                }

                issuedByUser {
                    ...userFields
                }
            }
        }

        ${universityFieldsFragment}
        ${productFieldsFragment}
        ${userFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                includeArchived: options.includeArchived,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<studentApplicationsQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])]
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
