import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ProductUpdateInput } from '../generated/globalTypes';
import { updateProductMutation, updateProductMutationVariables } from '../generated/updateProductMutation';
import { productFieldsFragment } from '../models/Product';

/**
 * Get a callback to update a Product in the store.
 */
export function useUpdateProductCallback(): [(id: string, model: ProductUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateProductMutation, updateProductMutationVariables>(
        gql`
            mutation updateProductMutation ($model: ProductUpdateInput!) {
                updateProduct(model: $model) {
                    ...productFields
                }
            }

            ${productFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: ProductUpdateInput) => {
        const result = await mutationAction({ variables: { model: { id: id, ...model } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
