import * as React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ProfileDetails } from "./ProfileDetails";
import { ChangePassword } from "./ChangePassword";
import { ChangeEmail } from "./ChangeEmail";
import { MainContainer } from "../shared/MainContainer";
import { Banner } from "../shared/Banner";
import { Background } from "../shared/Background";

/**
 * Allow the user to change their profile.
 */
export const Profile = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = React.useState<string>('profile');

    return (
        <Background>
            <Banner>
                <h1>{t('profile..heading', 'Profile')}</h1>
            </Banner>
            <MainContainer>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab === 'profile' ? 'active' : ''} onClick={e => setActiveTab('profile')}>
                            {t('profile.profile', 'Profile')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === 'email' ? 'active' : ''} onClick={e => setActiveTab('email')}>
                            {t('profile.changeEmail', 'Email')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === 'password' ? 'active' : ''} onClick={e => setActiveTab('password')}>
                            {t('profile.changePassword', 'Change Password')}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'profile'}>
                        <ProfileDetails />
                    </TabPane>
                    <TabPane tabId={'email'}>
                        <ChangeEmail />
                    </TabPane>
                    <TabPane tabId={'password'}>
                        <ChangePassword />
                    </TabPane>
                </TabContent>
            </MainContainer>
        </Background>
    );
};
