import * as React from 'react';
import { studentApplicationsQuery_items } from '../../api/main/generated/studentApplicationsQuery';
import { StudentApplicationsList } from './StudentApplicationsList';
import { useTranslation } from 'react-i18next';
import { calculateApplicationStatus } from '../../utilities/calculateApplicationStatus';

/**
 * List of rejected applications.
 */
export const RejectedStudentApplicationsList = () => {
    const { t } = useTranslation();

    // Filter to show only rejected items - if archived and paymentTakenDate is set but without a reference number it is a payment failure not a rejection
    const filter = React.useCallback((items: Array<studentApplicationsQuery_items> | undefined | null) => {
        return items?.filter(item => calculateApplicationStatus(item) === 'Rejected');
    }, []);

    return (
        <StudentApplicationsList filter={filter} includeArchived={true}
            title={t('rejectedStudentApplicationsList.title', 'Rejected applications')}
        />
    );
};