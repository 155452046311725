import './noSidebarUnlessRequired.scss';

import * as React from 'react';
import { Helmet } from 'react-helmet-async';


export const NoSidebarUnlessReqired = () => {
    return (
        <>
            <Helmet>
                <body data-no-sidebar-unless-required="true" />
            </Helmet>
        </>
    );
};
