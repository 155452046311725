import './personalDetails.scss';


import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormContainer } from '../../shared/FormContainer';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import moment from 'moment';

export interface PersonalDetailsProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}

/**
 * Wizard step to capture all personal details for the application.
 */
export const PersonalDetails = (props: PersonalDetailsProps) => {
    const {
        model, change,
        pushStep, popStep,
        validate, validationErrors
    } = props;

    const { t } = useTranslation();

    // Save the application to the database and progress to the next page.
    const validateAndNext = React.useCallback(() => {
        // Save everthing we've done so far to the database.
        if (!validate()) {
            return;
        }

        // Progress to the next page.
        pushStep(ApplyWizardStep.photo)
    }, [validate, pushStep]);

    return (
        <>
            <FormContainer className="personal-form" validationerrors={validationErrors}>
                <FormGroup>
                    <Label htmlFor="forename">
                        {t('personalDetails.forename.label', 'First name')} *
                    </Label>
                    <ValidatedInput name="forename" type="text" value={model?.forename ?? ''} onChange={e => change({ forename: e.currentTarget.value })} onBlur={e => validate('forename')} validationErrors={validationErrors['forename']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="surname">
                        {t('personalDetails.surname.label', 'Last name')} *
                    </Label>
                    <ValidatedInput name="surname" type="text" value={model?.surname ?? ''} onChange={e => change({ surname: e.currentTarget.value })} onBlur={e => validate('surname')} validationErrors={validationErrors['surname']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="dateOfBirth">
                        {t('personalDetails.dateOfBirth.label', 'Date of birth')} *
                    </Label>
                    <ValidatedInput name="dateOfBirth" type="date" value={moment(model?.dateOfBirth ?? '').local().format('YYYY-MM-DD')} onChange={e => change({ dateOfBirth: moment(e.currentTarget.value).format('YYYY-MM-DD') })} onBlur={e => validate('dateOfBirth')} validationErrors={validationErrors['dateOfBirth']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="email">
                        {t('personalDetails.email.label', 'Email')} *
                    </Label>
                    <ValidatedInput name="email" type="email" value={model?.email ?? ''} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="street">
                        {t('personalDetails.street.label', 'House number and Street')} *
                    </Label>
                    <ValidatedInput name="street" type="text" value={model?.street ?? ''} onChange={e => change({ street: e.currentTarget.value })} onBlur={e => validate('street')} validationErrors={validationErrors['street']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="address2">
                        {t('personalDetails.address2.label', 'Area')}
                    </Label>
                    <ValidatedInput name="address2" type="text" value={model?.address2 ?? ''} onChange={e => change({ address2: e.currentTarget.value })} onBlur={e => validate('address2')} validationErrors={validationErrors['address2']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="city">
                        {t('personalDetails.city.label', 'City')} *
                    </Label>
                    <ValidatedInput name="city" type="text" value={model?.city ?? ''} onChange={e => change({ city: e.currentTarget.value })} onBlur={e => validate('city')} validationErrors={validationErrors['city']} />
               </FormGroup>
                <FormGroup>
                    <Label htmlFor="county">
                        {t('personalDetails.county.label', 'County')}
                    </Label>
                    <ValidatedInput name="county" type="text" value={model?.county ?? ''} onChange={e => change({ county: e.currentTarget.value })} onBlur={e => validate('county')} validationErrors={validationErrors['county']} />
               </FormGroup>
                <FormGroup>
                    <Label htmlFor="postcode">
                        {t('personalDetails.postcode.label', 'Postcode')} *
                    </Label>
                    <ValidatedInput name="postcode" type="text" value={model?.postcode ?? ''} onChange={e => change({ postcode: e.currentTarget.value })} onBlur={e => validate('postcode')} validationErrors={validationErrors['postcode']} />
                </FormGroup>
                {t('personalDetails.mandatory', 'fields marked with * must be completed')}
            </FormContainer>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => validateAndNext()}>
                            {t('common.next', 'Next')}
                            <> </>
                            <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};