import './whoPays.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col } from 'reactstrap';
import { ChoiceButton } from '../shared/ChoiceButton';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface WhoPaysProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    isCurrentStepComplete: boolean,
    stepCompleted: ApplyWizardStep | undefined,
    setStepCompleted: (value: ApplyWizardStep) => void ,
}

/**
 * Wizard step to decide who pays.
 */
export const WhoPays = (props: WhoPaysProps) => {
    const {
        model,
        change,
        pushStep,
        isCurrentStepComplete,
        stepCompleted,
        setStepCompleted,
    } = props;

    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className={'text-center choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { pushStep(ApplyWizardStep.whichUniversity); setStepCompleted(!stepCompleted || ApplyWizardStep.whoPays > stepCompleted ? ApplyWizardStep.whoPays : stepCompleted); }}
                        // ENABLE THE NEXT LINE AND DISABLE THE OTHER onClick EVENTS TO STOP STUDENTS USING THE PORTAL UNTIL PASSES ARE AVAILABLE.
                        //onClick={() => { change({ universityId: null }); pushStep(ApplyWizardStep.paymentNotAllowedYet); setStepCompleted(!stepCompleted || ApplyWizardStep.whoPays > stepCompleted ? ApplyWizardStep.whoPays : stepCompleted); }}
                        title={t('whoPays.university', 'Start your application')}
                        text={t('whoPays.universityt', 'My school, college, local authority or accommodation provider is providing me with an academic pass')}
                        imageURL={`${stylesPaths.publicRoot}/img/ModelShoot132_NCT.jpg`}
                        className={model.universityId ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col className="text-center footer-link">
                    <a href="https://www.nctx.co.uk/easyrider/smartcards/">
                        {t('chooseType.wrongPlace', 'Not what you are looking for?  View or register for our general smart travel cards here.')}
                    </a>
                </Col>
            </Row>
        </>
        );
};