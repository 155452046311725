import * as React from 'react';
import { Layout } from './components/layout/Layout';
import { ServiceProviderContext } from 'inject-typesafe-react';
import { createServiceProvider } from './configure/configureServices';
import { AppRoutes } from './components/shared/AppRoutes';
import { appRoutes } from './configure/routeConfig';
import { initializeIcons } from './configure/iconsConfig';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/react-hooks';
import { apolloClient, apolloCachePersistor } from './configure/configureApollo';
import { configurei18n } from './configure/i18n';
import { LoadingIndicator } from './components/shared/LoadingIndicator';
import { I18nextProvider } from 'react-i18next';
import { createAppReduxStore } from './reduxStore/store';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import authService from './api/api-authorization/AuthorizeService';
import { configureErrorMessages } from './configure/errorMessageConfig';

// Configure redux.
const { store: reduxStore, persistor: reduxStorePersistor } = createAppReduxStore();

// Configure services.
const serviceProvider = createServiceProvider(reduxStore);

// When the authorization state changes, clear the right parts of our caches.
authService.subscribe(() => {
    reduxStorePersistor.purge();
    apolloCachePersistor.purge();
});

// Configure icons.
initializeIcons();

// Configure i18n
const i18n = configurei18n();

// Configure server and 3rd party error messages to make them friendly and translatable.
configureErrorMessages();

// App component.
export const App = () => {
    return (
        <HelmetProvider>
            <I18nextProvider i18n={i18n}>
                <ServiceProviderContext.Provider value={serviceProvider}>
                    <ReduxProvider store={reduxStore}>
                        <PersistGate persistor={reduxStorePersistor}>
                            <ApolloProvider client={apolloClient}>
                                <React.Suspense fallback={<LoadingIndicator fullWidth={true} />}>
                                    <Layout appRoutes={appRoutes}>
                                        <Helmet defaultTitle={i18n.t('Nottingham City Transport')} titleTemplate={i18n.t('%s | Nottingham City Transport')}>
                                        </Helmet>
                                        <AppRoutes routes={appRoutes} />
                                    </Layout>
                                </React.Suspense>
                            </ApolloProvider>
                        </PersistGate>
                    </ReduxProvider>
                </ServiceProviderContext.Provider>
            </I18nextProvider>
        </HelmetProvider>
    );
};
