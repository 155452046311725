import * as React from 'react';
import { studentApplicationsQuery_items } from '../../api/main/generated/studentApplicationsQuery';
import { StudentApplicationsList } from './StudentApplicationsList';
import { useTranslation } from 'react-i18next';
import { calculateApplicationStatus } from '../../utilities/calculateApplicationStatus';

/**
 * List of pending applications.
 */
export const PendingStudentApplicationsList = () => {
    const { t } = useTranslation();

    // Filter to show only pending items
    const filter = React.useCallback((items: Array<studentApplicationsQuery_items> | undefined | null) => {
        return items?.filter(item => calculateApplicationStatus(item) === 'Pending');
    }, []);

    return (
        <StudentApplicationsList filter={filter}
            title={t('pendingStudentApplicationsList.title', 'Pending applications')}
        />
        );
};