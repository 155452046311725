import './formContainer.scss'; 

import * as React from 'react';
import { Container, ContainerProps } from 'reactstrap';

export interface FormContainerProps extends ContainerProps {
}

/**
 * general input form container.
 */
export const FormContainer = (props: FormContainerProps) => {
    const { className, children, ...rest } = props;

    return (
        <Container className={`form-container ${className ?? ''}`} {...rest}>
            {children}
        </Container>
    );
};