/**
 * Constants for the available roles.
 */
export const IdentityRoles = {
    Administration: 'Administration'
};

///**
// * Constants (enum) for the available Roles.
// */
//export enum IdentityRoles {
//    Administration = 'Administration'
//}
