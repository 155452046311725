import * as React from 'react';
import { Apply } from './Apply';
import { ApplyWizardStep } from './ApplyWizardStep';

/**
 * Success after paying for an academic pass.
 */
export const Success = () => {
    return (
        <Apply
            initialStep={ApplyWizardStep.success}
        />
        );
};