import * as React from "react";
import { Alert, Button } from "reactstrap";
import { useConfirmEmailChangeCallback } from "../../api/account";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { MainContainer } from "../shared/MainContainer";
import { AccountBackground } from "./AccountBackground";
import { Banner } from "../shared/Banner";

/**
 * Confirm an email address change for an account using a unique link included in the email. 
 */
export const ConfirmEmailChange = () => {
    const { t } = useTranslation();
    const [emailConfirmed, setEmailConfirmed] = React.useState<boolean>(false);
    const [confirmEmail, { errors: confirmEmailErrors }] = useConfirmEmailChangeCallback();

    // Trigger the email confirmation on page load.
    React.useEffect(() => {
        (async () => {
            if (!emailConfirmed) {
                const ok = await confirmEmail();
                setEmailConfirmed(ok);
            }
        })();
    }, [confirmEmail, setEmailConfirmed, emailConfirmed]);

    // Render the UI.
    // 
    return (
        <AccountBackground>
            <Banner>
                {
                    confirmEmailErrors ? (
                            <h1>{t('confirmEmailChange.confirmEmailChangeFailureHeading', 'Sorry we cannot confirm your change of email using this code')}</h1>
                    ) : emailConfirmed ? (
                            <h1>{t('confirmEmailChange.confirmEmailChangeSuccessHeading', 'Thank you for confirming your change of email.')}</h1>
                    ) : (
                                <LoadingIndicator />
                            )
                    }
            </Banner>
            <MainContainer>
                {
                    confirmEmailErrors ? (
                        <>
                            <Alert color="danger">
                                {confirmEmailErrors}
                            </Alert>
                        </>
                    ) : emailConfirmed ? (
                        <>
                            <Alert color="success">
                                <>{t('confirmEmailChange.thankYouForConfirming', 'Thank you for confirming your change of email.  We\'ve swapped that over for you.')} </>
                            </Alert>
                            <LinkContainer to="/authentication/login">
                                <Button color="primary">
                                    {t('confirmEmailChange.signInToContinue', 'Please sign in to continue')}
                                </Button>
                            </LinkContainer>
                        </>
                    ) : null
                }
            </MainContainer>
        </AccountBackground>
    );
};
