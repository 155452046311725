import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { StudentApplicationUpdateInput } from '../generated/globalTypes';
import { updateStudentApplicationMutation, updateStudentApplicationMutationVariables } from '../generated/updateStudentApplicationMutation';
import { studentApplicationFieldsFragment } from '../models/StudentApplication';

/**
 * Get a callback to update a StudentApplication in the store.
 */
export function useUpdateStudentApplicationCallback(): [(id: string, model: StudentApplicationUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateStudentApplicationMutation, updateStudentApplicationMutationVariables>(
        gql`
            mutation updateStudentApplicationMutation ($model: StudentApplicationUpdateInput!) {
                updateStudentApplication(model: $model) {
                    ...studentApplicationFields
                }
            }

            ${studentApplicationFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: StudentApplicationUpdateInput) => {
        const result = await mutationAction({ variables: { model: { id: id, ...model } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
