import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col, Button } from 'reactstrap';
import { ChoiceButton } from '../shared/ChoiceButton';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product } from '../../../api/main/models/Product';
import moment from 'moment';
import { useSendPaymentConfirmEmailCallback } from '../../../api/main/studentApplications/useSendPaymentConfirmEmailCallback';
import { useSendPaymentFailEmailCallback } from '../../../api/main/studentApplications/useSendPaymentFailEmailCallback';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { useAsyncCallback } from 'react-use-async-callback';
import { useCallbackAwaitStateSync } from 'use-callback-await-state-sync';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface PayProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    product: Product | undefined | null,
    save: () => Promise<void>,
}

/**
 * Wizard step to test payment.
 */
export const PayFake = (props: PayProps) => {
    const {
        model,
        change,
        pushStep, popStep,
        product,
        save,
    } = props;

    const { t } = useTranslation();

    const [sendPaymentConfirmEmail, { /*isExecuting: isSendingPaymentConfirmEmail, */ errors: sendPaymentConfirmEmailErrors }] = useSendPaymentConfirmEmailCallback();
    const [sendPaymentFailEmail, { /*isExecuting: isSendingPaymentConfirmEmail, */ errors: sendPaymentFailEmailErrors }] = useSendPaymentFailEmailCallback();

    const [_saveAndNext, { errors: saveFormErrors }] = useAsyncCallback(async() => {
        // save the model and set the next step
        await save();

        if (!model.archived) {
            // successful payment
            sendPaymentConfirmEmail(model.id);
            pushStep(ApplyWizardStep.success);
        } else {
            // failed payment
            sendPaymentFailEmail(model.id);
            pushStep(ApplyWizardStep.failure);
        }
    }, [save, ApplyWizardStep, pushStep]);
    const saveAndNext = useCallbackAwaitStateSync(() => _saveAndNext());

    return (
        <>
            <AlertOnErrors errors={[sendPaymentConfirmEmailErrors, sendPaymentFailEmailErrors, saveFormErrors]} />
            <Row>
                <Col className={'text-right choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ hasPaid: true, price: product?.price, paymentProviderReferenceNumber: 'fake123', paymentTakenDate: moment().toISOString(), archived: false }); saveAndNext();}}
                        title={t('payFake.success', 'Payment Success')}
                        text={t('payFake.successt', 'Test payment success £{{productPrice}} collected', { productPrice: product?.price })}
                        imageURL={`${stylesPaths.publicRoot}/img/ModelShoot135_NCT.jpg`}
                    >
                    </ChoiceButton>
                </Col>
                <Col className={'text-left choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ hasPaid: false, price: product?.price, paymentProviderReferenceNumber: 'fake123', paymentTakenDate: moment().toISOString(), archived: true }); saveAndNext();}}
                        title={t('payFake.failure', 'Payment Failure')}
                        text={t('payFake.failuret', 'Test payment failure £{{productPrice}} not collected', { productPrice: product?.price })}
                        imageURL={`${stylesPaths.publicRoot}/img/ModelShoot135_NCT.jpg`}
                    >
                    </ChoiceButton>
                </Col>
            </Row>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                    </Col>
                </Row>
            </div>
        </>
        );
};