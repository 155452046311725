import gql from "graphql-tag";
import { productFields } from "../generated/productFields";

export const productFieldsFragment = gql`
    fragment productFields on Product {
        id
        name
        price
        isUnder19
        isAnydayPass
        isCityArea
        archived
        anydayDays
    }
`;


export type Product = Omit<productFields, '__typename'>;
