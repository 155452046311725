import './editStudentApplication.scss';

import * as React from 'react';
import { Button, Label, FormGroup, Spinner, Row, Col, Input } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useStudentApplication } from '../../api/main/studentApplications/useStudentApplication';
import { useSaveStudentApplicationCallback } from '../../api/main/studentApplications/useSaveStudentApplicationCallback';
import { StudentApplication } from '../../api/main/models/StudentApplication';
import { Background } from '../shared/Background';
import moment from 'moment';
import { ISODateTimeInput } from '../shared/ISODateTimeInput';
import { useProfile } from '../../api/main/profiles/useProfile';
import { useCurrentUserId } from '../../api/account';
import { useCallbackAwaitStateSync } from 'use-callback-await-state-sync';
import { calculateApplicationStatus } from '../../utilities/calculateApplicationStatus';

/**
 * Edit an studentApplication
 */
export const EditStudentApplication = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useStudentApplication(id);
    const { model, change, changes } = useChanges<StudentApplication>(storeModel);
    const [save, { errors: saveErrors }] = useSaveStudentApplicationCallback();
    const history = useHistory();

    const { data: { model: issuedByProfile } } = useProfile(model?.issuedByUserId, { isUserId: true });

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {

        await save(model.id, changes, false);

        history.goBack();
    }, [save, model, changes, history]);

    const currentuserID = useCurrentUserId();

    const updateModelAndSave = useCallbackAwaitStateSync(() => saveForm());

    const applicationStatus = calculateApplicationStatus(model);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            <ConditionalFragment showIf={applicationStatus === 'Pending'}>
                                {t('editStudentApplication.editHeading', 'Process application')}
                            </ConditionalFragment>
                            <ConditionalFragment showIf={applicationStatus !== 'Pending'}>
                                {t('editStudentApplication.viewHeading', 'View application')}
                            </ConditionalFragment>
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors]} />

                <div id="editStudentApplication-form">
                    <Row>
                        <FormGroup className="col-auto">
                            <Label htmlFor="forename">
                                {t('editStudentApplication.forename.label', 'First name')}
                            </Label>
                            <Input readOnly plaintext name="forename" type="text" value={model.forename ?? ''} />
                        </FormGroup>
                        <FormGroup className="col-auto">
                            <Label htmlFor="surname">
                                {t('editStudentApplication.surname.label', 'Last name')}
                            </Label>
                            <Input readOnly plaintext name="surname" type="text" value={model.surname ?? ''} />
                        </FormGroup>
                        <FormGroup className="col-auto">
                            <Label htmlFor="email">
                                {t('editStudentApplication.email.label', 'Email')}
                            </Label>
                            <Input readOnly plaintext name="email" type="email" value={model.email ?? ''} />
                        </FormGroup>
                        <FormGroup className="col-auto">
                            <Label htmlFor="dateOfBirth">
                                {t('editStudentApplication.dateOfBirth.label', 'Date of birth')}
                            </Label>
                            <ISODateTimeInput readOnly plaintext name="dateOfBirth" type="date" value={model.dateOfBirth ?? ''} />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="col-auto">
                            <Label>
                                {t('editStudentApplication.existingnumber.label', 'Existing Easyrider card number')}
                            </Label>
                            <Input readOnly plaintext name="existingEasyRiderCardNumber" type="text" value={model.existingEasyRiderCardNumber ?? ''} />
                        </FormGroup>

                    </Row>
                    <Row>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label>
                                    {t('editStudentApplication.address.label', 'Address')}
                                </Label>
                                <Input readOnly plaintext name="street" type="text" value={model.street ?? ''} />
                                <ConditionalFragment showIf={!!model.address2}>
                                    <Input readOnly plaintext name="address2" type="text" value={model.address2 ?? ''} />
                                </ConditionalFragment>
                                <Input readOnly plaintext name="city" type="text" value={model.city ?? ''} />
                                <ConditionalFragment showIf={!!model.county}>
                                    <Input readOnly plaintext name="county" type="text" value={model.county ?? ''} />
                                </ConditionalFragment>
                                <Input readOnly plaintext name="postcode" type="text" value={model.postcode ?? ''} />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                            <Row>
                                <Col xs="4" className="inline-group text-center">
                                    <FormGroup>
                                        <Label>
                                            {t('editStudentApplication.photo.label', 'Uploaded photo')}
                                        </Label>
                                    </FormGroup>
                                    <a className="btn btn-outline-secondary pt-3 mb-3" href={storeModel?.photoBlobReference?.url} target="_blank" rel="noopener noreferrer">
                                        <img className="uploaded-photo-image" src={storeModel?.photoBlobReference?.url} alt="Uploaded" />
                                        <FontAwesomeIcon icon="download" />
                                        <> {t('common.download', 'Download photo')}</>
                                    </a>
                                </Col>
                                <Col xs="8" className="inline-group text-center">
                                    <FormGroup>
                                        <Label>
                                            {t('editStudentApplication.evidence.label', 'Evidence photo')}
                                        </Label>
                                    </FormGroup>
                                    <img className="uploaded-photo-image evidence-photo" src={storeModel?.proofOfEntitlementBlobReference?.url} alt="Evidence" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label>
                                    {t('editStudentApplication.cardType.label', 'Application details')}
                                </Label>
                                <Input readOnly plaintext name="applicantType" type="text" value={model.isUnder19 ? 'Under 19' : 'Student'} />
                                <Input readOnly plaintext name="cardType" type="text" value={model.isAnydayPass ? 'Anyday' : 'Everyday'} />
                                <Input readOnly plaintext name="cardZone" type="text" value={model.isCityArea ? 'City area' : 'Further'} />
                                <Input readOnly plaintext name="period" type="text" value={model.isAnydayPass ? model.anydayDays : 'Academic year'} />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label>
                                    {t('editStudentApplication.cardType.label', 'Product to issue')}
                                </Label>
                                <Input readOnly plaintext name="product" type="text" value={storeModel?.product?.name ?? ''} />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    {t('editStudentApplication.whospaying.label', 'Who\'s paying')}
                                </Label>
                                <Input readOnly plaintext name="payer" type="text" value={storeModel?.university?.name ?? 'Applicant'} />
                            </FormGroup>
                            <Row noGutters>
                                <Col xs="6" className="inline-group">
                                    <FormGroup>
                                        <Label>
                                            {t('editStudentApplication.price.label', 'Price')}
                                        </Label>
                                        <Input readOnly plaintext name="price" type="text" value={'£' + model?.price?.toFixed(2)} />
                                    </FormGroup>
                                </Col>
                                <ConditionalFragment showIf={!model.universityId}>
                                    <Col xs="6" className="inline-group">
                                        <FormGroup>
                                            <Label>
                                                {t('editStudentApplication.amountpaid.label', 'Paid by applicant')}
                                            </Label>
                                            <Input readOnly plaintext name="paid" type="text" value={model.hasPaid ? '£' + model?.price?.toFixed(2) : '£0.00'} />
                                        </FormGroup>
                                    </Col>
                                </ConditionalFragment>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>
                                    {t('editStudentApplication.applicationProgress.label', 'Application progress')}
                                </Label>
                            </FormGroup>
                            <Row noGutters>
                                <FormGroup className="col-auto">
                                    <Label htmlFor="submittedDate">
                                        {t('editStudentApplication.applicationDate.label', 'Date of application')}
                                    </Label>
                                    <ISODateTimeInput readOnly plaintext name="dateOfBirth" type="date" value={model.submittedDate ?? ''} />
                                </FormGroup>
                                <ConditionalFragment showIf={!!storeModel?.paymentProviderReferenceNumber}>
                                    <FormGroup className="col-auto">
                                        <Label>
                                            {t('editStudentApplication.applicationProgress.paymentProviderReferenceNumber.label', 'Payment Provider Reference')}
                                        </Label>
                                        <Input readOnly plaintext name="paymentProviderReferenceNumber" type="text" value={storeModel?.paymentProviderReferenceNumber} />
                                    </FormGroup>

                                </ConditionalFragment>
                                <FormGroup className="col-auto">
                                    <Label>
                                        {t('editStudentApplication.applicationProgress.status.label', 'Status')}
                                    </Label>
                                    <Input readOnly plaintext name="status" type="text" value={applicationStatus} />
                                </FormGroup>
                            </Row>
                            <ConditionalFragment showIf={!!storeModel?.issuedByUserId}>
                                <Row>
                                    <FormGroup className="col-auto">
                                        <Label>
                                            {applicationStatus === 'Rejected' ? t('editStudentApplication.rejectedByUser.label', 'Rejected by') : t('editStudentApplication.issuedByUser.label', 'Issued by')}
                                        </Label>
                                        <Input readOnly plaintext name="issuedByUser" type="text" value={issuedByProfile?.firstName + ' ' + issuedByProfile?.lastName} />
                                    </FormGroup>
                                    <FormGroup className="col-auto">
                                        <Label htmlFor="issuedOnDate">
                                            {t('editStudentApplication.issuedOnDate.label', 'Date')}
                                        </Label>
                                        <ISODateTimeInput readOnly plaintext name="issuedOnDate" type="date" value={model.issuedOnDate ?? ''} />
                                    </FormGroup>
                                </Row>
                            </ConditionalFragment>



                        </Col>
                    </Row>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading && applicationStatus === 'Pending'}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => {
                                change({ issuedByUserId: currentuserID, issuedOnDate: moment().toISOString(), hasBeenIssued: true });
                                updateModelAndSave();
                            }
                            }
                                executingChildren={<><Spinner size="sm" /> {t('editStudentApplication.saving', 'saving...')}</>}>
                                <FontAwesomeIcon icon="check-circle" />
                                <> {t('editStudentApplication.passissued', 'Easyrider pass issued')}</>
                            </ButtonAsync>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!isLoading && applicationStatus === 'Pending'}>
                            <ButtonAsync color="danger" isExecuting={isSaving} onClick={() => {
                                change({ issuedByUserId: currentuserID, issuedOnDate: moment().toISOString(), archived: true });
                                updateModelAndSave();
                            }
                            }
                                executingChildren={<><Spinner size="sm" /> {t('editStudentApplication.saving', 'saving...')}</>}>
                                <FontAwesomeIcon icon="times-circle" />
                                <> {t('editStudentApplication.passrejected', 'Application rejected')}</>
                            </ButtonAsync>
                        </ConditionalFragment>

                        {/*
                             //hide save button for now but we may need it if editing required
                               <ConditionalFragment showIf={isLoading}>
                                   <ButtonAsync color="primary" isExecuting={isSaving}
                                       executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                       <FontAwesomeIcon icon="save" />
                                       <> {t('common.save', 'Save')}</>
                                   </ButtonAsync>
                               </ConditionalFragment>
                        */}
                        <ConditionalFragment showIf={!isLoading && applicationStatus === 'Pending'}>
                            <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && applicationStatus !== 'Pending'}>
                            <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                                {t('common.close', 'Close')}
                            </Button>
                        </ConditionalFragment>

                    </FormButtons>
                </div>
            </MainContainer>
        </Background>
    );
};
