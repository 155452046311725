import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { SidebarNavTitle } from '../../shared/sidebarNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { IdentityRoles } from '../../configure/security/IdentityRoles';

export const AdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <AuthorizeContainer>
            <SidebarNavTitle>
                {t('administrationNavigation.administrationHeading', 'Administration')}
            </SidebarNavTitle>

            <SidebarNavTitle child>
                {t('administrationNavigation.userAdministrationHeading', 'Student applications')}
            </SidebarNavTitle>
            <NavItem>
                <NavLink to="/administration/applications/pending" tag={NavLinkTrackActive}>
                    <FontAwesomeIcon icon="person-booth" className="nav-icon" />
                    <> {t('administrationNavigation.pendingApplications', 'Pending applications')}</>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/administration/applications/completed" tag={NavLinkTrackActive}>
                    <FontAwesomeIcon icon="vote-yea" className="nav-icon" />
                    <> {t('administrationNavigation.completedApplications', 'Issued cards')}</>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/administration/applications/rejected" tag={NavLinkTrackActive}>
                    <FontAwesomeIcon icon="trash-alt" className="nav-icon" />
                    <> {t('administrationNavigation.rejectedApplications', 'Rejected applications')}</>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/administration/applications/failed" tag={NavLinkTrackActive}>
                    <FontAwesomeIcon icon="pound-sign" className="nav-icon" />
                    <> {t('administrationNavigation.failedPaymentApplications', 'Failed payments')}</>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/administration/applications/search" tag={NavLinkTrackActive}>
                    <FontAwesomeIcon icon="search" className="nav-icon" />
                    <> {t('administrationNavigation.searchApplications', 'Search all applications')}</>
                </NavLink>
            </NavItem>

            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <SidebarNavTitle child>
                    {t('administrationNavigation.activityAdministrationHeading', 'General administration')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/bulk-buyers" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="school" className="nav-icon" />
                        <> {t('administrationNavigation.universities', 'Bulk buying organisations')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/administration/product-prices" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="pound-sign" className="nav-icon" />
                        <> </>{t('administrationNavigation.products', 'Product pricing')}
                    </NavLink>
                </NavItem>

                <SidebarNavTitle child>
                    {t('administrationNavigation.userAdministrationHeading', 'User administration')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/users" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        <> {t('administrationNavigation.users', 'Users')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </AuthorizeContainer>
    );
};
