import './choiceButton.scss';

import * as React from 'react';
import { Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface ChoiceButtonProps {
    active?: boolean,
    title: string,
    text?: string,
    text2?: string,
    className?: string,
    children?: React.ReactNode,
    onClick?: () => void,
    imageURL?: string,
    linkURL?: string,
    linkText?: string,
}

/**
 * Useful nice looking button we use to let the user choose between options.
 * @param props
 */
export const ChoiceButton = (props: ChoiceButtonProps) => {
    const {
        active,
        title,
        text,
        text2,
        children,
        className,
        onClick,
        imageURL,
        linkURL,
        linkText,
    } = props;

    return (
        <Button color={active ? 'secondary' : 'light'} className={`choice-button ${className ?? ''}`}
            onClick={onClick}
        >
            <Card>
                <CardBody tag="div">
                    <div className="choice-button-children">
                        {children}
                    </div>
                    <img src={imageURL} alt=""/>
                    <CardTitle>
                        {title}
                    </CardTitle>
                    <CardText>
                        {text}
                        <ConditionalFragment showIf={!!text2}>
                            <br />
                            <strong>{text2}</strong>
                        </ConditionalFragment>
                        <br /><br />
                        <ConditionalFragment showIf={!!linkURL}>
                            <a href={linkURL}>{linkText}</a>
                        </ConditionalFragment>
                    </CardText>

                </CardBody>
            </Card>
        </Button>
        );
};