
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col } from 'reactstrap';
import { ChoiceButton } from '../shared/ChoiceButton';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface PaymentNotAllowedProps {
}

/**
 * Wizard step to decide who pays.
 */
export const PaymentNotAllowed = (props: PaymentNotAllowedProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className={'text-center choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { }}
                        title={t('paymentNotAllowed.notavailable', 'Student offers are not currently available online')}
                        text={t('paymentNotAllowed.notavailablet', 'Our offers on student passes are available to buy online between August and the end of October.')}
                        text2={t('paymentNotAllowed.notavailablet2', 'Please use the NCTX Buses app or apply in person at the Travel Centre outside of these dates.')}
                        imageURL={`${stylesPaths.publicRoot}/img/ModelShoot135_NCT.jpg`}
                        className={''}
                        linkURL={'https://www.nctx.co.uk/easyrider/smartcards/'}
                        linkText={t('paymentNotAllowed.notavailablelink', 'Register for our general smart travel cards here.')}
                    >
                    </ChoiceButton>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col className="text-center footer-link">
                    <a href="https://www.nctx.co.uk/easyrider/smartcards/">
                        {t('chooseType.wrongPlace', 'Not looking for an academic pass?  View or register for our general smart travel cards here.')}
                    </a>
                </Col>
            </Row>
        </>
        );
};