import { StudentApplication } from "../api/main/models/StudentApplication";
import { studentApplicationsQuery_items } from "../api/main/generated/studentApplicationsQuery";

/**
 * Calculate application status for the passed in application.
 * @param application
 */
export function calculateApplicationStatus(model: StudentApplication | studentApplicationsQuery_items): string {
    // default to pending
    var ret = 'Pending';

    if (model.hasBeenIssued && !model.archived) {
        // cannot archive an issued card so no other check needed
        ret = 'Issued';
    } else if (model.archived && (!model.paymentTakenDate || model.hasPaid || model.universityId)) {
        // rejected if they have paid and then been archived 
        // or a university is paying not the applicant(they don't pay so cannot be a payment failure).
        ret = 'Rejected';
    } else if (model.archived) {
        // any other archived must be a payment failure
        ret = 'Payment failed';
    }

    return ret;
}