import './header.scss';

import * as React from 'react';
import { Nav, Container, Row, Col, NavItem } from 'reactstrap';
import { AppHeader, AppSidebarToggler } from '@coreui/react';
import { UserNav } from './UserNav';
import { Link, useLocation } from 'react-router-dom';
//import { Navigation } from './Navigation';
//import { LanguageSelect } from './LanguageSelect';
import { AppNavbarBrand } from './AppNavbarBrand';
import { useTranslation } from 'react-i18next';

export const Header = () => {
    const { t } = useTranslation();

    const location = useLocation();
    const isAdministration = location.pathname.indexOf('/administration') === -1 ? false : true;

    return (
        <AppHeader fixed className="header">
            <div className="header-account-bar">
                <Container fluid={isAdministration}>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <Nav className="menu-nav d-none d-md-flex flex-grow-1">
                                <NavItem>
                                    <a className="nav-link" href="https://www.nctx.co.uk/mobile-tickets">
                                        {t('header.buyTickets', 'Buy tickets')}
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <a className="nav-link" href="https://www.nctx.co.uk/favourites">
                                        {t('header.favourites', 'Favourites')}
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <a className="nav-link" href="https://www.nctx.co.uk/login">
                                        {t('header.login', 'Login / Register')}
                                    </a>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container fluid={isAdministration}>
                <AppSidebarToggler className="d-lg-none" display="md" mobile />

                <Link to="/">
                    <AppNavbarBrand />
                </Link>
                <AppSidebarToggler className="d-md-down-none flex-shrink" display="lg" />

                <Nav className="menu-nav d-none d-md-flex flex-grow-1">
                    {/*<Navigation />*/}
                </Nav>
                <Nav className="menu-nav-xs d-flex d-md-none flex-grow-1">
                </Nav>
            
                <Nav className="signin-nav">
                    <UserNav />
                </Nav>

                {/*<Nav className="language-nav">
                    <NavItem>
                        <LanguageSelect />
                    </NavItem>
                </Nav>*/}
            </Container>

            <div className="header-ribbon">
            </div>
        </AppHeader>
    );
};
