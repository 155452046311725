import { RouteEntry } from "../../shared/routes";
import { Apply } from "./Apply";
import { Success } from "./Success";
import { Failure } from "./Failure";


export const applyRoutes: Array<RouteEntry> = [
    { path: '/apply', exact: true, component: Apply },
    { path: '/apply/success/:id', exact: true, component: Success },
    { path: '/apply/failure/:id', exact: true, component: Failure },
];
