import { AsyncActionStatus } from '../../../shared/abstractStore';
import { useServices } from 'inject-typesafe-react';
import { AppServices } from '../../../configure/configureServices';
import { useAsyncCallback } from 'react-use-async-callback';

/**
 * Send an email to let the user know the paymentfailed.
 * @param id
 * @param options
 */
export function useSendPaymentFailEmailCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const apiFetch = useServices((services: AppServices) => services.apiFetch());

    // Prepare a callback that calls the rest API to send the email.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        await apiFetch.get(`/api/payment/sendPaymentFailEmail/${id}`);
    }, [apiFetch]);

    return [action, { isExecuting: isExecuting, errors: actionErrors }];
}

