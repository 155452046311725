import './photo.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Input, Button, Spinner, FormGroup } from 'reactstrap';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsyncCallback } from "react-use-async-callback";
import { useUploadBlobCallback } from '../../../api/main/blobReferences/useUploadBlobCallback';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { Guid } from 'guid-string';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { CustomEasyriderCard } from '../shared/CustomEasyriderCard';

export interface photoProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    photoBlob: BlobReference | undefined,
    setPhotoBlob: (value: BlobReference | undefined) => void,
    cardImageUrl: string,
}

/**
 * Wizard step to upload applicant's photo for the card.
 */
export const Photo = (props: photoProps) => {
    const {
        model, change,
        pushStep, popStep,
        photoBlob, setPhotoBlob,
        cardImageUrl,
    } = props;

    const { t } = useTranslation();

    // Upload a photo of the applicant.
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();
    const [uploadFile, { isExecuting: isUploadingFile, errors: uploadFileErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the file.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        change({ photoBlobReferenceId: blob.id });
        setPhotoBlob(blob);
    }, [uploadBlob, change, t, setPhotoBlob]);

    // Can we continue to the next page?
    const canContinue = React.useMemo(() => {
        if (!model) {
            return false;
        }

        return !Guid.isEmpty(model.photoBlobReferenceId);
    }, [model]);

    return (
        <>
            <div className="photo-upload-container">
                <AlertOnErrors errors={[uploadBlobErrors, uploadFileErrors]} />
                <CustomEasyriderCard model={model} cardImageUrl={cardImageUrl} photoBlob={photoBlob} />

                <FormGroup className="text-center">
                    <Label className="btn btn-outline-primary photo-upload-button">
                        {
                            isUploadingFile ? (
                                <>
                                    <Spinner size="sm" />
                                    <> </>{t('photo.uploading', 'Uploading...')}
                                </>
                            ) : (
                                    <>{t('photo.uploadFile', 'Upload file...')}</>
                                )
                        }

                        <Input type="file" name="files" onChange={e => uploadFile(e.currentTarget.files)} />
                    </Label>
                </FormGroup>
                <div className="alert">
                    The Easyrider Smartcard you are purchasing requires a photograph.
                    The photo must be:
                    <ul>
                        <li>in colour</li>
                        <li>a true likeness of you and of your full head</li>
                        <li>free of any reflection or glare from glasses</li>
                        <li>of you on your own</li>
                    </ul>
                    You must:
                    <ul>
                        <li>face forward and look straight into the camera</li>
                        <li>look natural with no facial expressions, for example, smiling, grinning or frowning</li>
                        <li>have nothing covering your eyes, for example, hair or glasses frame</li>
                        <li>not wear sunglasses or tinted glassed</li>
                        <li>not wear a hat or cover your head unless for medical or religious reasons. Religious headwear can be worn, but it must not cover your face.</li>
                    </ul>
                    <a href="https://images.nctx.co.uk/downloads/Niqab-Leaflet.pdf" target="_blank" rel="noopener noreferrer">View our advice for customers wearing a Niqab.</a><br/><br/>
                    <b>If an inappropriate or poor quality image is uploaded, you will be asked to provide an acceptable replacement, which will delay the issuing of your Easyrider smartcard.</b>
                </div>
                <div className="mt-2 mb-2">
                    <Row>
                        <Col xs="auto">
                            <Button color="primary" onClick={() => popStep()}>
                                <FontAwesomeIcon icon="chevron-left" />
                                <> </>
                                {t('common.back', 'Back')}
                            </Button>
                        </Col>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" disabled={!canContinue} onClick={() => { model.isUnder19 ? pushStep(ApplyWizardStep.evidenceUnder19) : pushStep(ApplyWizardStep.evidence); }}>
                                {t('common.next', 'Next')}
                                <> </>
                                <FontAwesomeIcon icon="chevron-right" />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};