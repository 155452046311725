import * as React from 'react';
import { Apply } from './Apply';
import { ApplyWizardStep } from './ApplyWizardStep';

/**
 * Failure after paying for an academic pass.
 */
export const Failure = () => {

    return (
        <Apply
            initialStep={ApplyWizardStep.failure}
        />
        );
};