import './stickyToolbar.scss';

import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { Container } from 'reactstrap';

export interface StickyToolbarProps {
    className?: string,
    children: React.ReactNode
}

/**
 * Container for a toolbar that sticks to the top of the screen when its scrolled off the screen.
 * 
 * NOTE Some code here is specific to the layout rather than the overall functionality.  Namely:
 *  1. The use of the "main" class when top-toolbar-stuck which is used to make sure available margins are kept in sync with the <main> element
 *     during sidebar states.
 *  2. The topOffset="" which is used to ensure the triggering happens when we would move behind the fixed nav header.
 *  
 *  If you want to use this code elsewhere, you will likely need to tweak these values.
 */
export const StickyToolbar = (props: StickyToolbarProps) => {
    const [isStuck, setIsStuck] = React.useState<boolean>(false);
    const { className, children } = props;

    return (
        <>
            <Waypoint topOffset="54px" scrollableAncestor={window} onEnter={() => setIsStuck(false)} onLeave={() => setIsStuck(true)} />

            {
                isStuck ? (
                    <div className={`sticky-toolbar sticky-toolbar-stuck main ${className ?? ''}`}>
                        <Container fluid>
                            {children}
                        </Container>
                    </div>
                ) : (
                        <div className={`sticky-toolbar sticky-toolbar-unstuck ${className ?? ''}`}>
                            {children}
                        </div>
                    )
            }
        </>
        );
};