import './isCityArea.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col, Button, Media } from 'reactstrap';
import { ChoiceButton } from '../shared/ChoiceButton';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConditionalFragment } from 'react-conditionalfragment';
import { stylesPaths } from '../../../configure/stylesPaths';

export interface IsCityAreaProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    calculateProduct: () => void,
    isCurrentStepComplete: boolean,
    stepCompleted: ApplyWizardStep | undefined,
    setStepCompleted: (value: ApplyWizardStep) => void,
}

/**
 * Wizard step to decide City Area or Further.
 */
export const IsCityArea = (props: IsCityAreaProps) => {
    const {
        model,
        change,
        pushStep, popStep,
        calculateProduct,
        isCurrentStepComplete,
        stepCompleted,
        setStepCompleted,
    } = props;

    const mapImage = `${stylesPaths.publicRoot}/img/Nottingham_Eazyrider_Zone_Map.jpg`;
    const { t } = useTranslation();

    return (
        <>
            <ConditionalFragment showIf={!model.isUnder19}>
                <div className={'alert alert-success'}>
                    Nottingham Trent University students please select City Zone Only. Your travel card will cover all of our services, including to Brackenhurst Campus thanks to our partnership with and financial support from NTU.
            </div>
            </ConditionalFragment>
            <Row>
                <Col className={'text-right choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ isCityArea: true }); calculateProduct(); pushStep(ApplyWizardStep.existingEasyrider); setStepCompleted(!stepCompleted || ApplyWizardStep.isCityArea > stepCompleted ? ApplyWizardStep.isCityArea : stepCompleted) }}
                        title={t('isCityArea.true', 'City Zone Only')}
                        text={t('isCityArea.truet', 'This is the Greater Nottingham area and covers nearly all of our services, except for parts of the 1, 26/A, 46 and 47.')}
                        imageURL={`${stylesPaths.publicRoot}/img/COVID051_NCT.jpg`}
                        className={model.isCityArea && isCurrentStepComplete ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
                <Col className={'text-left choice-button-container'}>
                    <ChoiceButton
                        onClick={() => { change({ isCityArea: false }); calculateProduct(); pushStep(ApplyWizardStep.existingEasyrider); setStepCompleted(!stepCompleted || ApplyWizardStep.isCityArea > stepCompleted ? ApplyWizardStep.isCityArea : stepCompleted) }}
                        title={t('isCityArea.false', 'City Zone and Outer Zone')}
                        text={t('isCityArea.falset', 'This covers all of our services without restriction.')}
                        imageURL={`${stylesPaths.publicRoot}/img/EastLeake12-NCT.jpg`}
                        className={!model.isCityArea && isCurrentStepComplete ? 'highlight-selected' : ''}
                    >
                    </ChoiceButton>
                </Col>
            </Row>
            <Media className={'mb-2 mt-2 ml-auto mr-auto zone-image'} src={mapImage} alt='Zone Map' />

            <div className={'mt-2 mb-2'}>
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                    </Col>
                </Row>
            </div>
        </>
    );
};