import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { userRoutes } from "../components/users/routes";
import { NotFound } from "../components/shared/NotFound";
import { administrationSidebarRoutes, administrationRoutes } from "../components/administration/routes";
import { NoSidebarUnlessReqired } from "../components/shared/NoSidebarUnlessRequired";
import { homeRoutes } from "../components/home/routes";
import { applyRoutes } from "../components/apply/routes";
import { universityRoutes } from "../components/universities/routes";
import { productRoutes } from "../components/products/routes";
import { studentApplicationRoutes } from "../components/studentApplications/routes";


export const appRoutes: Array<RouteEntry> = [
    ...administrationRoutes,
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...homeRoutes,
    ...profileRoutes,
    ...userRoutes,

    ...applyRoutes,

    ...productRoutes,
    ...studentApplicationRoutes,
    ...universityRoutes,

    { path: undefined, component: NotFound }
];

export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,

    { path: undefined, component: NoSidebarUnlessReqired }
];