import * as React from "react";
import { Alert, Button } from "reactstrap";
import { useConfirmEmailCallback } from "../../api/account";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import { AccountBackground } from "./AccountBackground";
import { Banner } from "../shared/Banner";

/**
 * Confirm an email address for an account using a unique link included in the email. 
 */
export const ConfirmEmail = () => {
    const { t } = useTranslation();
    const [emailConfirmed, setEmailConfirmed] = React.useState<boolean>(false);
    const [confirmEmail, { errors: confirmEmailErrors }] = useConfirmEmailCallback();

    // Trigger the email confirmation on page load.
    React.useEffect(() => {
        (async () => {
            if (!emailConfirmed) {
                const ok = await confirmEmail();
                setEmailConfirmed(ok);
            }
        })();
    }, [confirmEmail, setEmailConfirmed, emailConfirmed]);

    // Render the UI.
    // 
    return (
        <AccountBackground>
            <Banner>
                {
                    confirmEmailErrors ? (
                        <h1>{t('confirmEmail.confirmEmailFailureHeading', 'Sorry we cannot confirm your email using this code')}</h1>
                    ) : emailConfirmed ? (
                        <h1>{t('confirmEmail.confirmEmailSuccessHeading', 'Thanks for confirming your email')}</h1>
                    ) : (
                            <LoadingIndicator />
                            )
                }
            </Banner>
            <MainContainer>
                {
                    confirmEmailErrors ? (
                        <>
                            <Alert color="danger">
                                {confirmEmailErrors}
                            </Alert>
                        </>
                    ) : emailConfirmed ? (
                            <>
                                <Alert color="success">
                                    <>{t('confirmEmail.thankYouForConfirming', 'Thank you for confirming your email.  You account setup is now complete.')} </>
                                </Alert>
                                <LinkContainer to="/authentication/login">
                                    <Button color="primary">
                                        {t('confirmEmail.signInToContinue', 'Please sign in to continue')}
                                    </Button>
                                </LinkContainer>
                            </>
                        ) : null
                }
                </MainContainer>
        </AccountBackground>
    );
};
