import * as React from 'react';
import { ProductCreateInput, ProductUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateProductCallback } from './useCreateProductCallback';
import { useUpdateProductCallback } from './useUpdateProductCallback';
import { Product } from '../models/Product';

/**
 * Get a callback to save a Product in the store using either a create or update.
 */
export function useSaveProductCallback(options: SaveInStoreOptions<Product, string> = {}) {
    const [_create, createStatus] = useCreateProductCallback();
    const create = React.useCallback((model: Partial<Product>) => _create(model as ProductCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateProductCallback();
    const update = React.useCallback((id: string, changes: Partial<Product>) => _update(id, changes as ProductUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
