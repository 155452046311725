import * as React from 'react';
import { Redirect } from 'react-router';
import { useSearchParams } from '../../shared/useURLSearchParams';

/**
 * Default / home page.
 */
export const Home = () => {
    // If we have a redirect URL on the query string redirect there.
    var { redirectUrl } = useSearchParams();

    return (
        <>
            <Redirect to={redirectUrl ?? '/apply'} />
        </>
        );
};