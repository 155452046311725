import './customEasyriderCard.scss';

import * as React from 'react';
import { Media } from 'reactstrap';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { BlobReference } from '../../../api/main/models/BlobReference';

export interface CustomEasyriderCardProps {
    model: StudentApplication,
    className?: string,
    cardImageUrl: string,
    photoBlob: BlobReference | undefined,
}

/**
 * Easyrider card with applicant name and photo. 
 */
export const CustomEasyriderCard = (props: CustomEasyriderCardProps) => {
    const {
        model, className, 
        cardImageUrl, photoBlob,
    } = props;

    const easyriderImage = cardImageUrl;

    const imageToUse = React.useMemo(() => {
        if (!model) {
            return '';
        }

        return photoBlob ? photoBlob.url : '';

    }, [model, photoBlob]);

    const formattedCardName = React.useMemo(() => {
        return model.forename + ' ' + model.surname;

    }, [model]);

    return (
        <div className={`easyrider-card-container ${className ?? ''}`}>
            <Media className="mb-4 ml-auto mr-auto easyrider-image" src={easyriderImage} alt="card background" />
            <Media className="uploaded-photo-image" src={imageToUse} alt="uploaded photo" />
            <div className="easyrider-card-name">{formattedCardName}</div>
        </div>

        );
};