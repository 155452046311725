import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UniversityCreateInput } from '../generated/globalTypes';
import { createUniversityMutation, createUniversityMutationVariables } from '../generated/createUniversityMutation';
import { universityFieldsFragment } from '../models/University';

/**
 * Get a callback to create a University in the store.
 */
export function useCreateUniversityCallback(): [(model: UniversityCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createUniversityMutation, createUniversityMutationVariables>(
        gql`
            mutation createUniversityMutation ($model: UniversityCreateInput!) {
                createUniversity(model: $model) {
                    ...universityFields
                }
            }

            ${universityFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: UniversityCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
