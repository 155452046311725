import * as React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useProfileForInvite } from "../../api/main/profiles/useProfileForInvite";

/**
 * Allow the user to set their personal details on their profile during registration.
 */
export const ConfirmInviteProfileDetails = () => {
    const { t } = useTranslation();

    const { data: { model }, errors: loadingErrors } = useProfileForInvite();

    // Render the UI.
    //
    return (
        <>
            <AlertOnErrors errors={loadingErrors} />

            <FormGroup>
                <Label htmlFor="firstName">{t('confirmInviteProfileDetails.firstName', 'First name')}</Label>
                <Input name="firstName" type="text" value={model?.firstName ?? ''} readOnly />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="lastName">{t('confirmInviteProfileDetails.lastName', 'Last name')}</Label>
                <Input name="lastName" type="text" value={model?.lastName ?? ''} readOnly />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="lastName">{t('confirmInviteProfileDetails.email', 'Email')}</Label>
                <Input name="lastName" type="email" value={model?.user?.email ?? ''} readOnly />
            </FormGroup>
        </>
    );
};
