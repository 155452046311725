import * as React from 'react';
import { UniversityCreateInput, UniversityUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateUniversityCallback } from './useCreateUniversityCallback';
import { useUpdateUniversityCallback } from './useUpdateUniversityCallback';
import { University } from '../models/University';

/**
 * Get a callback to save a University in the store using either a create or update.
 */
export function useSaveUniversityCallback(options: SaveInStoreOptions<University, string> = {}) {
    const [_create, createStatus] = useCreateUniversityCallback();
    const create = React.useCallback((model: Partial<University>) => _create(model as UniversityCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateUniversityCallback();
    const update = React.useCallback((id: string, changes: Partial<University>) => _update(id, changes as UniversityUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
