import { RouteEntry } from "../../shared/routes";
import { UsersList } from './UsersList';
import { EditUser, CreateUser } from "./EditUser";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { DeleteUser } from "./DeleteUser";

export const userRoutes: Array<RouteEntry> = [
    { path: '/administration/users', exact: true, component: UsersList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/edit/:id', component: EditUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/add', component: CreateUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/delete/:id', component: DeleteUser, authorize: true, requireRole: IdentityRoles.Administration },
];
