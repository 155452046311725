import * as React from 'react';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { Product } from '../../../api/main/models/Product';
import { paymentConfig } from '../../../configure/paymentConfig';
import { PayFake } from './PayFake';
import { PayReal } from './PayReal';

export interface PayProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    save: () => Promise<void>,
    product: Product | undefined | null,
}

/**
 * Wizard step to test payment.
 */
export const Pay = (props: PayProps) => {
    return (
        <>
            {
                paymentConfig.useFakePaymentPage ? (
                    <PayFake {...props} />
                ) : (
                        <PayReal {...props} />
                        )
            }
        </>
        );
};