import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useSendPasswordResetEmailCallback } from "../../api/account";
import { ForgotPassword as ForgotPasswordModel } from '../../api/account/models/ForgotPassword';
import { useTranslation } from "react-i18next";
import { MainContainer } from "../shared/MainContainer";
import { AccountBackground } from "./AccountBackground";
import { Banner } from "../shared/Banner";

/**
 * Request a password reset emai because the user has forgotten their password.
 */
export const ForgotPassword = () => {
    const { t } = useTranslation();
    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    const [sendResetEmail, { isExecuting: isSendingResetEmail, errors: sendResetEmailErrors }] = useSendPasswordResetEmailCallback();

    const { model, change } = useChanges<ForgotPasswordModel>({ email: '' });

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            email: () => !model.email ? t('forgotPassword.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Render the UI.
    // 
    return (
        <AccountBackground>
            <Banner>
                <h1>{t('forgotPassword.forgotPasswordHeading', 'Forgot your password?')}</h1>
                <p className="text-mute">
                    {t('forgotPassword.helpText', 'Enter your email and we\'ll send you a link you can use to recover your account and set a new password.')}
                </p>
            </Banner>
            <MainContainer>
                <Form onSubmit={async e => {
                    e.preventDefault();
                    const ok = await sendResetEmail(model.email);
                    setEmailSent(ok);
                }}>

                    <AlertOnErrors simple errors={sendResetEmailErrors} />
                    {
                        emailSent ? (
                            <Alert color="success">
                                <>{t('forgotPassword.emailHasBeenSent', 'Please check your email and follow the link to reset your password.')} </>
                                <ButtonAsync type="submit" color="success" isExecuting={isSendingResetEmail}
                                    executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                    {t('common.resendEmail', 'Resend email')}
                                </ButtonAsync>
                            </Alert>
                        ) : null
                    }
                    <FormGroup>
                        <Label htmlFor="email">{t('forgotPassword.email', 'Email')}</Label>
                        <ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                    </FormGroup>
                    <FormGroup>
                        <ButtonAsync type="submit" color="primary" isExecuting={isSendingResetEmail}
                            executingChildren={<><Spinner size="sm" /> {t('common.sending', 'Sending...')}</>}>
                            {t('common.send', 'Send')}
                        </ButtonAsync>
                    </FormGroup>
                </Form>
            </MainContainer>
        </AccountBackground>
    );
};
