import { Library, library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faLock, faUser, faSearch, faPlus, faCogs, faSave, faEdit, faUsers, faTrash, faCaretUp, faCaretDown, faCaretLeft, faUndo, faMinus, faExclamationCircle, faCaretRight, faTimesCircle, faCalendarWeek, faBusinessTime, faStopwatch, faUserClock, faTimes, faStop, faDesktop, faRuler, faFlagCheckered, faPlay, faUserCheck, faCreditCard, faPhone,  faEnvelope, faSchool, faPoundSign, faVoteYea, faPersonBooth, faTrashAlt, faChevronLeft, faChevronRight, faDownload, faCross, faCheckCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { faFrown as farFrown, faFrownOpen as farFrownOpen, faCircle as farCircle, } from '@fortawesome/free-regular-svg-icons';

// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faBusinessTime,
        faCalendarWeek,
        faCaretUp,
        faCaretDown,
        faCaretLeft,
        faCaretRight,
        faCheckCircle,
        faChevronLeft,
        faChevronRight,
        faCogs,
        faCreditCard,
        faCross,
        faDesktop,
        faDownload,
        faEdit,
        faExclamationCircle,
        faEye,
        faFlagCheckered,
        faLock,
        faMinus,
        faPersonBooth,
        faPlay,
        faPlus,
        faPoundSign,
        faRuler,
        faSave,
        faSchool,
        faSearch,
        faSpinner,
        faStop,
        faStopwatch,
        faTimes,
        faTimesCircle,
        faTrash,
        faTrashAlt,
        faUndo,
        faUser,
        faUserCheck,
        faUserClock,
        faUsers,
        faVoteYea,

        // regular
        farCircle,
        farFrown,
        farFrownOpen,
        faPhone,
        faEnvelope,
    );
};
