import { ServiceResolver, ServiceProvider, IServiceCollectionBuilder } from "inject-typesafe";
import { AccountService } from "../api/account";
import { ApiFetch } from 'apifetch-json';
import { CacheService } from "../shared/cacheInRedux";
import { AppReduxStore } from "../reduxStore/store";
import { EnhancedStore, CombinedState } from "@reduxjs/toolkit";

/**
 * Returns a configured service provider.
 */
export function createServiceProvider(reduxStore: EnhancedStore<CombinedState<AppReduxStore>>) {
    return new ServiceProvider((builder: IServiceCollectionBuilder<AppServices>) => ({
        accountService: builder.singleton(services => new AccountService()),
        apiFetch: builder.singleton(services => new ApiFetch()),
        cacheService: builder.singleton(services => new CacheService(() => services.reduxStore().getState().cache, services.reduxStore().dispatch)),
        reduxStore: builder.singleton(() => reduxStore),
    }));
}

export interface AppServices {
    accountService: ServiceResolver<AccountService>,
    apiFetch: ServiceResolver<ApiFetch>,
    cacheService: ServiceResolver<CacheService>,
    reduxStore: ServiceResolver<EnhancedStore<CombinedState<AppReduxStore>>>,
}

