import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Banner } from '../shared/Banner';
import { Container, Row, Col, Button } from 'reactstrap';
import { useStudentApplicationSupportingData } from '../../api/main/studentApplications/useStudentApplicationSupportingData';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ApplyWizardStep } from './ApplyWizardStep';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { WhoPays } from './steps/WhoPays';
import { PaymentNotAllowed } from './steps/PaymentNotAllowed';
import { useChanges } from '../../shared/useChanges';
import { StudentApplication } from '../../api/main/models/StudentApplication';
import { Guid } from 'guid-string';
import { WhichUniversity } from './steps/WhichUniversity';
import { IsUnder19 } from './steps/IsUnder19';
import { IsAnyday } from './steps/IsAnyday';
import { AnydayDays } from './steps/AnydayDays';
import { IsCityArea } from './steps/IsCityArea';
import { ExistingEasyrider } from './steps/ExistingEasyrider';
import { PersonalDetails } from './steps/PersonalDetails';
import { Photo } from './steps/Photo';
import { Evidence } from './steps/Evidence';
import { EvidenceUnder19 } from './steps/EvidenceUnder19';
import { BlobReference } from '../../api/main/models/BlobReference';
import { useSaveStudentApplicationCallback } from '../../api/main/studentApplications/useSaveStudentApplicationCallback';
import { useAsyncCallback } from 'react-use-async-callback';
import moment from 'moment';
import { useCallbackAwaitStateSync } from 'use-callback-await-state-sync';
import { useValidatorCallback } from 'pojo-validator-react';
import { Pay } from './steps/Pay';
import { Success } from './steps/Success';
import { Failure } from './steps/Failure';
import { useStudentApplication } from '../../api/main/studentApplications/useStudentApplication';
import { useParams } from 'react-router';
import { useEffectOnceWhen } from 'use-effect-once-when';
import { stylesPaths } from '../../configure/stylesPaths';
import { forceApplyOnSecureNctxCoUk } from '../../configure/secureNctxCoUkConfig';
import { PaymentNotAllowedYet } from './steps/PaymentNotAllowedYet';

export interface ApplyProps {
    initialStep?: ApplyWizardStep,
}

/**
 * Apply for an academic pass.
 */
export const Apply = (props: ApplyProps) => {
    const { initialStep } = props;
    const { id } = useParams();

    const { t } = useTranslation();

    // Redirect if we can't apply here.
    React.useEffect(() => {
        if (forceApplyOnSecureNctxCoUk) {
            window.location.href = 'https://secure.nctx.co.uk/StudentOffer/';
        }
    }, []);

    // Load data we need from the server.
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadingErrors } = useStudentApplication(id ?? Guid.empty);
    const { data: { universities, products }, isLoading: isLoadingSupportingData, errors: loadingSupportingDataErrors } = useStudentApplicationSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Create model for capturing the data.
    const { model, change, changes } = useChanges<StudentApplication>(
        storeModel,
        !!id ? {}:
        { // if we are doing a create then initialise an empty model to be populated.
        id: Guid.newGuid(),
        isCityArea: true,
        hasPaid: false,
        address2: '',
        archived: false,
        city: '',
        country: '',
        county: '',
        dateOfBirth: null,
        email: '',
        existingEasyRiderCardNumber: '',
        forename: '',
        hasBeenIssued: false,
        isAnydayPass: false,
        anydayDays: 0,
        issuedByUserId: '',
        issuedOnDate: moment().toISOString(),
        isUnder19: false,
        paymentProviderReferenceNumber: '',
        paymentTakenDate: null,
        photoBlobReferenceId: null,
        postcode: '',
        price: 0,
        productId: '',
        proofOfEntitlementBlobReferenceId: null,
        street: '',
        submittedDate: moment().toISOString(),
        surname: '',
        universityId: null,
        universityProvidedReferenceNumber: '',
    });

    // Calculates and sets the productId based on the model's current state.
    const _calculateProduct = React.useCallback(() => {
        // Find the right product.
        const product = products?.find(item => item.isAnydayPass === model?.isAnydayPass && item.anydayDays === model?.anydayDays && item.isCityArea === model?.isCityArea);
        change({ productId: product?.id ?? '', price: product?.price ?? 0 });
    }, [model, change, products]);
    const calculateProduct = useCallbackAwaitStateSync(() => _calculateProduct());

    // Calculate which product we re currently using.
    const product = React.useMemo(() => products?.find(item => item.id === model?.productId), [products, model]);

    // sends the url for the right template back ready for the uploaded photo overlay
    const cardImageUrl = React.useMemo(() => {
        // default to Everyday City
        var returnString = `${stylesPaths.publicRoot}/img/Template_ER_Everyday.png`;
        if (product?.isAnydayPass && product?.isCityArea) { returnString = `${stylesPaths.publicRoot}/img/Template_ER_Anyday.png`; }
        if (product?.isAnydayPass && !product?.isCityArea) { returnString = `${stylesPaths.publicRoot}/img/Template_ER_Anyday_Further.png`; }
        if (!product?.isAnydayPass && !product?.isCityArea) { returnString = `${stylesPaths.publicRoot}/img/Template_ER_Everyday_Further.png`; }
        return returnString;
    }, [product]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            forename: () => !model?.forename ? t('apply.firstNameRequired', 'First name is required') : '',
            surname: () => !model?.surname ? t('apply.surnameRequired', 'Last name is required') : '',
            dateOfBirth: () => !model?.dateOfBirth ? t('apply.dateOfBirthRequired', 'Date of birth is required') :
                moment(model?.dateOfBirth).toISOString() > moment().toISOString() ? t('apply.dateOfBirthInvalid', 'Date of birth cannot be in the future.') : '',
            email: () =>
                !model?.email ? t('apply.emailRequired', 'Email is required') :
                    model.email.indexOf('.') < 0 ||
                        model.email.indexOf('@') < 0 ||
                        model.email.indexOf('.') === model.email.length - 1 ||
                        model.email.indexOf('@') === model.email.length - 1 ? t('apply.emailFormat', 'Email is not properly formatted. It must conatain a "." and a "@"') : '',
            street: () => !model?.street ? t('apply.cityRequired', 'House number and Street is required') : '',
            city: () => !model?.city ? t('apply.cityRequired', 'City is required') : '',
            postcode: () => !model?.postcode ? t('apply.postcodeRequired', 'Postcode is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [save, { errors: saveErrors }] = useSaveStudentApplicationCallback();
    const [hasBeenSaved, setHasBeenSaved] = React.useState<boolean>(false);
    const [saveForm, { errors: saveFormErrors }] = useAsyncCallback(async () => {

        await save(model.id, changes, !hasBeenSaved);

        setHasBeenSaved(true);
    }, [save, model, changes, hasBeenSaved, setHasBeenSaved]);

    // Photo blobs.
    const [photoBlob, setPhotoBlob] = React.useState<BlobReference | undefined>();
    const [evidenceBlob, setevidenceBlob] = React.useState<BlobReference | undefined>();

    // Default our blobs from the store once they become available (only used when reloading a saved application e.g. after payment success).
    useEffectOnceWhen(
        !!storeModel,
        () => {
            setPhotoBlob(storeModel?.photoBlobReference ?? undefined);
            setevidenceBlob(storeModel?.proofOfEntitlementBlobReference ?? undefined);
        }, [setPhotoBlob, setevidenceBlob]);

    // Manage the step 
    const [stepHistory, setStepHistory] = React.useState<Array<ApplyWizardStep>>([initialStep ?? ApplyWizardStep.whoPays]);

    // Push a new step onto the stack.
    const pushStep = React.useCallback((newStep: ApplyWizardStep) => {
        setStepHistory(prevState => {
            // If this step is already at the top of the stack do nothing.
            const current = prevState[prevState.length - 1];
            if (newStep === current) {
                return prevState;
            }

            // Otherwise add the new step to the top of the stack.
            return [...prevState, newStep];
        });
    }, [setStepHistory]);

    // Pop the top step off the stack (so we go back to the previous page) or if toStep is provided, until we hit the requested step or the start.
    const popStep = React.useCallback((untilStep?: ApplyWizardStep) => {
        setStepHistory(prevState => {
            if (prevState.length <= 1) {
                return prevState;
            }

            let ret = [...prevState];
            let current: ApplyWizardStep;
            do {
                current = ret.pop() as ApplyWizardStep;
                // Until we hit the step we are looking for (or the bottom of the stack).
            } while (!!untilStep && untilStep !== current && ret.length > 1);

            return ret;
        });
    }, [setStepHistory]);

    // Current step to show.
    const currentStep = React.useMemo(() => stepHistory[stepHistory.length - 1], [stepHistory]);

    const [stepCompleted, setStepCompleted] = React.useState<ApplyWizardStep>();

    // check if the current step is before the last step completed and so is itself complete
    const isCurrentStepComplete = React.useMemo(() => {
        if (stepCompleted === undefined) {
            return false;
        }
        return stepCompleted >= currentStep;
    }, [stepCompleted, currentStep]);


    // Get the current step to show (done this way to make sure we can use a switch and get compiler warnings if we miss a step).
    const { title, control: currentStepControl } = React.useMemo(() => {
        switch (currentStep) {
            case ApplyWizardStep.whoPays:
                return {
                    title: t('apply.title.whoPays', 'You can apply for your academic pass here if it is being provided for you.'),
                    //title: t('apply.title.whoPays', 'Academic passes will be available soon'),
                    control: (<WhoPays model={model} change={change} pushStep={pushStep} stepCompleted={stepCompleted} setStepCompleted={setStepCompleted} isCurrentStepComplete={isCurrentStepComplete} />),
                };
            case ApplyWizardStep.paymentNotAllowed:
                return {
                    title: t('apply.title.paymentNotAllowed', 'Student offers are not currently available online'),
                    control: (<PaymentNotAllowed />),
                };
            case ApplyWizardStep.paymentNotAllowedYet:
                return {
                    title: t('apply.title.paymentNotAllowedYet', 'Academic passes will be available soon'),
                    control: (<PaymentNotAllowedYet />),
                };
            case ApplyWizardStep.whichUniversity:
                return {
                    title: t('apply.title.whichUniversity', 'Which organisation is providing your pass?'),
                    control: (<WhichUniversity model={model} change={change} pushStep={pushStep} popStep={popStep} universities={universities} stepCompleted={stepCompleted} setStepCompleted={setStepCompleted} />),
                };
            case ApplyWizardStep.isUnder19:
                return {
                    title: t('apply.title.isUnder19', 'Do you require a student pass or under 19 pass?'),
                    control: (<IsUnder19 model={model} change={change} pushStep={pushStep} popStep={popStep} calculateProduct={calculateProduct} stepCompleted={stepCompleted} setStepCompleted={setStepCompleted} isCurrentStepComplete={isCurrentStepComplete} />),
                };
            case ApplyWizardStep.isAnyday:
                return {
                    title: t('apply.title.isAnyday', 'Will you need an Everyday or Anyday pass?'),
                    control: (<IsAnyday model={model} change={change} pushStep={pushStep} popStep={popStep} calculateProduct={calculateProduct} stepCompleted={stepCompleted} setStepCompleted={setStepCompleted} isCurrentStepComplete={isCurrentStepComplete} />),
                };
            case ApplyWizardStep.anydayDays:
                return {
                    title: t('apply.title.anydayDays', 'How many days of travel would would you like?'),
                    control: (<AnydayDays model={model} change={change} pushStep={pushStep} popStep={popStep} calculateProduct={calculateProduct} stepCompleted={stepCompleted} setStepCompleted={setStepCompleted} isCurrentStepComplete={isCurrentStepComplete} />),
                };
            case ApplyWizardStep.isCityArea:
                return {
                    title: t('apply.title.isCityArea', 'Which Zone would you like your pass to cover?'),
                    control: (<IsCityArea model={model} change={change} pushStep={pushStep} popStep={popStep} calculateProduct={calculateProduct} stepCompleted={stepCompleted} setStepCompleted={setStepCompleted} isCurrentStepComplete={isCurrentStepComplete} />),
                };
            case ApplyWizardStep.existingEasyrider:
                return {
                    title: t('apply.title.existingEasyrider', 'Do you have an Existing Easyrider card?'),
                    control: (<ExistingEasyrider model={model} change={change} pushStep={pushStep} popStep={popStep} stepCompleted={stepCompleted} setStepCompleted={setStepCompleted}/>),
                };
            case ApplyWizardStep.personalDetails:
                return {
                    title: t('apply.title.personalDetails', 'Please complete your personal details here'),
                    control: (<PersonalDetails model={model} change={change} pushStep={pushStep} popStep={popStep} validate={validate} validationErrors={validationErrors}/>),
                };
            case ApplyWizardStep.photo:
                return {
                    title: t('apply.title.photo', 'Upload a photo of yourself for your Easyrider pass'),
                    control: (<Photo model={model} change={change} pushStep={pushStep} popStep={popStep} photoBlob={photoBlob} setPhotoBlob={setPhotoBlob} cardImageUrl={cardImageUrl} />),
                };
            case ApplyWizardStep.evidence:
                return {
                    title: t('apply.title.evidence', 'Upload a photo of your student card'),
                    control: (<Evidence model={model} change={change} pushStep={pushStep} popStep={popStep} evidenceBlob={evidenceBlob} setEvidenceBlob={setevidenceBlob} save={saveForm} />),
                };
            case ApplyWizardStep.evidenceUnder19:
                return {
                    title: t('apply.title.evidence', 'Upload a photo of your photo ID that shows your date of birth'),
                    control: (<EvidenceUnder19 model={model} change={change} pushStep={pushStep} popStep={popStep} evidenceBlob={evidenceBlob} setEvidenceBlob={setevidenceBlob} save={saveForm} />),
                };
            case ApplyWizardStep.pay:
                return {
                    title: t('apply.title.pay', 'You can pay for your Easyrider pass here'),
                    control: (<Pay model={model} change={change} pushStep={pushStep} popStep={popStep} product={product} save={saveForm} />),
                };
            case ApplyWizardStep.failure:
                return {
                    title: t('apply.title.failure', 'Your payment failed'),
                    control: (<Failure popStep={popStep} />),
                };
            case ApplyWizardStep.success:
                return {
                    title: t('apply.title.success', 'Successful application'),
                    control: (<Success model={model} cardImageUrl={cardImageUrl} photoBlob={photoBlob}/>),
                };
            default:
                return {
                    title: 'TODO',
                    control: (<>TODO <Button onClick={() => popStep()}>{t('common.back', 'Back')}</Button></>)
                };
        }
    }, [t, model, change, pushStep, popStep, currentStep, universities, evidenceBlob, photoBlob, saveForm, product, calculateProduct, validate, validationErrors, cardImageUrl, isCurrentStepComplete, stepCompleted]);

    // Render the UI
    //
    return (
        <div className="choose-type">
            <Banner>
                <Container>
                    <Row>
                        <Col>
                            <h1>
                                {title}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </Container>
            </Banner>
            <Container>
                <AlertOnErrors errors={[loadingErrors, loadingSupportingDataErrors, saveErrors, saveFormErrors]} />

                <div>
                    {currentStepControl}
                </div>
            </Container>
        </div>
        );
};