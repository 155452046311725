import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {  Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { ApplyWizardStep } from '../ApplyWizardStep';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { University } from '../../../api/main/models/University';
import { Guid } from 'guid-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormContainer } from '../../shared/FormContainer';

export interface WhichUniversityProps {
    model: StudentApplication,
    change: (changes: Partial<StudentApplication>) => void,
    pushStep: (newStep: ApplyWizardStep) => void,
    popStep: (untilStep?: ApplyWizardStep) => void,
    universities: Array<University> | undefined | null,
    stepCompleted: ApplyWizardStep | undefined,
    setStepCompleted: (value: ApplyWizardStep) => void,
}

/**
 * Wizard step to decide which bulk buyer (university model) is paying.
 */
export const WhichUniversity = (props: WhichUniversityProps) => {
    const {
        model, change,
        pushStep, popStep,
        universities,
        stepCompleted,
        setStepCompleted,
   } = props;

    const { t } = useTranslation();

    // Currently selected university.
    const university = React.useMemo(() => {
        if (!model || !universities) {
            return null;
        }

        return universities.find(item => item.id === model.universityId);
    }, [model, universities]);

    // Can we continue to the next page?
    const canContinue = React.useMemo(() => {
        if (!model) {
            return false;
        }

        return !Guid.isEmpty(model.universityId);
    }, [model]);

    return (
        <>
            <FormContainer>
                <FormGroup>
                    <Label htmlFor="universityId">
                        {t('whichUniversity.universityId.label', 'Which school, college, local authority or accommodation provider is providing your pass?')}
                    </Label>
                    <Input type="select" value={model?.universityId ?? ''} onChange={e => change({ universityId: e.currentTarget.value })}>
                        <option value="">{t('whichUniversity.pleaseSelect', '(Please select who is providing your pass)')}</option>
                        {
                            universities?.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                        }
                    </Input>
                </FormGroup>


                <FormGroup>
                    <Label htmlFor="universityId">
                        {t('whichUniversity.univeristyProvidedReferenceNumber.label', 'If {{name}} has given you a reference number for your pass please provide it here.  If you don\'t have a reference number you can leave this blank.', { name: university?.name ?? 'your provider'})}
                    </Label>
                    <Input type="text" value={model?.universityProvidedReferenceNumber ?? ''} onChange={e => change({ universityProvidedReferenceNumber: e.currentTarget.value })} />
                </FormGroup>
            </FormContainer>

            <div className="mt-2 mb-2">
                <Row>
                    <Col xs="auto">
                        <Button color="primary" onClick={() => popStep()}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs="auto">
                        <Button color="primary" disabled={!canContinue} onClick={() => {
                                setStepCompleted(!stepCompleted || ApplyWizardStep.whichUniversity > stepCompleted ? ApplyWizardStep.whichUniversity : stepCompleted);
                                // Progress to the next page.
                                pushStep(ApplyWizardStep.isAnyday);
                            }}>
                            {t('common.next', 'Next')}
                            <> </>
                            <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
        );
};