import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { ProductsList } from "./ProductsList";
import { EditProduct, CreateProduct } from "./EditProduct";
import { DeleteProduct } from "./DeleteProduct";


export const productRoutes: Array<RouteEntry> = [
    { path: '/administration/product-prices', exact: true, component: ProductsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/product-prices/edit/:id', component: EditProduct, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/product-prices/add', component: CreateProduct, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/product-prices/delete/:id', component: DeleteProduct, authorize: true, requireRole: IdentityRoles.Administration },
];
