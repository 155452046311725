/**
 * Steps we work through when completing the Apply wizard.
 */
export enum ApplyWizardStep {
    whoPays,
    paymentNotAllowed,
    paymentNotAllowedYet,
    whichUniversity,
    isUnder19,
    isAnyday,
    anydayDays,
    isCityArea,
    existingEasyrider,
    personalDetails,
    photo,
    evidence,
    evidenceUnder19,
    pay,
    success,
    failure,
}

