import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StudentApplication } from '../../../api/main/models/StudentApplication';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { CustomEasyriderCard } from '../shared/CustomEasyriderCard';

export interface SuccessProps {
    model: StudentApplication,
    photoBlob: BlobReference | undefined,
    cardImageUrl: string,
}

/**
 * Wizard step to test payment.
 */
export const Success = (props: SuccessProps) => {
    const {
        model,
        photoBlob,
        cardImageUrl,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <div className="mt-2">
                <div className="alert alert-success">
                    <p>
                        {t('success.thanks', 'Thank you ')} {model.forename}. {t('success.paymentsuccessful', 'Your application was successful.')}
                    </p>
                </div>
                <CustomEasyriderCard model={model} cardImageUrl={cardImageUrl} photoBlob={photoBlob} />
                <div className="alert">
                    {t('success.next', 'Here\'s what will happen next.')} <br /><br />
                    {t('success.send', 'We\'ll print your Easyrider card and send it to you at the address you provided:')} <br />
                    <b>{model?.street},&nbsp;
                    {model?.address2 ? model?.address2 + ', ' : null}
                    {model?.city},&nbsp;
                    {model?.county ? model?.county + ', ' : null}
                    {model?.postcode},&nbsp;</b><br /><br />
                    {t('success.email', 'You\'ll also receive an email confirming your purchase.')}
                </div>
            </div>
        </>
    );
};